import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import SidebarPengembang from '../SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';

const PengembangPengajuanBgh = () => {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    // const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();


    let refreshToken = null;

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);

    const { loginContext } = useContext(AuthContext);


    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/pengembang/listPengajuanBghByPengembangId/' + user.pengembang_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const handleAddPengajuan = () => {
        // user['pemasaranrumah_state'] = 'add';
        // user['pemasaranrumah_id'] = null
        // loginContext(user);

        user['pengajuanbgh_id'] = '';
        
        loginContext(user); // isi context
        navigate(`/depan/pengembang/PengembangPengajuanBghTambah`);
        // history.push(`/igahp/pengembang/PengembangPengajuanBghTambah`);
    };


    let sequenceNumber = 1;

  return (
    <>



          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <section className="content-header">
                  <div className="container-fluid">
                      <div className="row mb-4 mt-2">
                          <div className="col-sm-6">
                              {/* <h1>List Pengajuan BGH</h1> */}
                              <h1 className="text-muted fw-light">LIST DATA<b> PENGAJUAN BGH</b></h1>
                          </div>
                          <div className="col-sm-6">
                              <ol className="breadcrumb float-sm-right">
                                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                                  <li className="breadcrumb-item active">List Data Pengajuan BGH</li>
                              </ol>
                          </div>
                      </div>

                      {isLoading ?
                          <p>Loading Data...</p> :

                          <div className="card">
                              
                              <div className="card-header">
                                  <button type="submit" onClick={handleAddPengajuan} className="btn btn-success">Tambah Pengajuan</button>

                                  <div className="card-info">
                                      {/* <button type="submit" onClick={handleAddLokasi} className="btn btn-primary">Tambah Lokasi</button> */}
                                  </div>
                                  <div className="row">
                                      <div className="col-12">

                                      </div>
                                  </div>
                              </div>
                              {/* /.card-header */}
                              <div className="card-body">
                                  <table id="example1" className="table table-bordered table-striped">
                                      <thead>
                                          <tr>
                                              <th>NO</th>
                                              <th>NO PENGAJUAN</th>
                                              <th>NAMA PENGEMBANG</th>
                                              <th>NAMA PERUMAHAN</th>
                                              <th>STATUS </th>
                                              <th>TGL PERMOHONAN</th>
                                              <th>TAHAPAN</th>
                                              <th>AKSI</th>
                                          </tr>
                                      </thead>
                                      <tbody>

                                          {data.map((item) => (
                                              <tr key={item.id}>
                                                  <td>{sequenceNumber++}</td>
                                                  <td>{item.nomorPermohonan}</td>
                                                  <td>{item.namaPengembang}</td>
                                                  <td>{item.namaPerumahan}</td>
                                                  <td>{item.statusPengajuan}</td>
                                                  <td>{item.tglPermohonan}</td>
                                                  <td>{item.tahapPermohonan}</td>


                                                  {/* <td>{item.jenis_perumahan === 0 ? 'Rumah Tapak' : 'Rumah Susun'}</td>
                                                    <td>{item.verified === true ? 'Verified' : 'Belum Verified'}</td> */}
                                                  <td>
                                                      <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                          onClick={(event) => {
                                                              user['pengajuanbgh_id'] = item.id;
                                                              user['perumahanId'] = item.perumahanId;
                                                              loginContext(user); // isi context
                                                              // alert("allo");
                                                            //   navigate(`/igahp/pemda/PengajuanBghDetail`);
                                                              navigate(`/depan/pengembang/PengembangPengajuanBghTambah`);
                                                          }}
                                                      >
                                                          <i className="fas fa-eye" />
                                                      </button>

                                                      {/* <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                            
                                                            onClick={(event) => {
                                                                user['perumahan_id'] = item.id;
                                                                user['perumahan_nama'] = item.nama_perumahan;
                                                                user['perumahan_state'] = 'edit';
                                                                loginContext(user); // isi context

                                                                navigate(`/igahp/pengembang/PengembangLokasiTambah`);
                                                            }}
                                                        >
                                                            <i className="fas fa-edit" id={`i-${item.id}`} />

                                                        </button>


                                                        <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                            <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                        </button> */}
                                                  </td>
                                              </tr>
                                          ))}

                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      }


                  </div>{/* /.container-fluid */}
              </section>
              {/* Main content */}
              <section className="content">
                  <div className="container-fluid">



                      {/* /.row */}
                  </div>
                  {/* /.container-fluid */}
              </section>
              {/* /.content */}
          </div>
          {/* /.content-wrapper */}




{/* 
          <div className="content-wrapper">
              
              <section className="content-header">
                  <div className="container-fluid">
                      <div className="row mb-2">
                          <div className="col-sm-6">
                              <h1>Data Pengajuan BGH</h1>
                          </div>
                          <div className="col-sm-6">
                              <ol className="breadcrumb float-sm-right">
                                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                                  <li className="breadcrumb-item active">Data Pengajuan BGH</li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </section>
              
              <section className="content">
                  <div className="container-fluid">
                      <div className="row">
                          <div className="col-12">
                              
                              
                              <div className="card">
                                  <div className="card-header">
                                      <button type="submit" onClick={handleAddPengajuan} className="btn btn-primary">Tambah Pengajuan</button>
                                  </div>
                              
                                  <div className="card-body">
                                      <table id="example1" className="table table-bordered table-striped">
                                          <thead>
                                              <tr>
                                                  <th>Rendering engine</th>
                                                  <th>Browser</th>
                                                  <th>Platform(s)</th>
                                                  <th>Engine version</th>
                                                  <th>CSS grade</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr>
                                                  <td>Trident</td>
                                                  <td>Internet
                                                      Explorer 4.0
                                                  </td>
                                                  <td>Win 95+</td>
                                                  <td> 4</td>
                                                  <td>X</td>
                                              </tr>
                                              <tr>
                                                  <td>Trident</td>
                                                  <td>Internet
                                                      Explorer 5.0
                                                  </td>
                                                  <td>Win 95+</td>
                                                  <td>5</td>
                                                  <td>C</td>
                                              </tr>
                                              <tr>
                                                  <td>Trident</td>
                                                  <td>Internet
                                                      Explorer 5.5
                                                  </td>
                                                  <td>Win 95+</td>
                                                  <td>5.5</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Trident</td>
                                                  <td>Internet
                                                      Explorer 6
                                                  </td>
                                                  <td>Win 98+</td>
                                                  <td>6</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Trident</td>
                                                  <td>Internet Explorer 7</td>
                                                  <td>Win XP SP2+</td>
                                                  <td>7</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Trident</td>
                                                  <td>AOL browser (AOL desktop)</td>
                                                  <td>Win XP</td>
                                                  <td>6</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Firefox 1.0</td>
                                                  <td>Win 98+ / OSX.2+</td>
                                                  <td>1.7</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Firefox 1.5</td>
                                                  <td>Win 98+ / OSX.2+</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Firefox 2.0</td>
                                                  <td>Win 98+ / OSX.2+</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Firefox 3.0</td>
                                                  <td>Win 2k+ / OSX.3+</td>
                                                  <td>1.9</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Camino 1.0</td>
                                                  <td>OSX.2+</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Camino 1.5</td>
                                                  <td>OSX.3+</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Netscape 7.2</td>
                                                  <td>Win 95+ / Mac OS 8.6-9.2</td>
                                                  <td>1.7</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Netscape Browser 8</td>
                                                  <td>Win 98SE+</td>
                                                  <td>1.7</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Netscape Navigator 9</td>
                                                  <td>Win 98+ / OSX.2+</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.0</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>1</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.1</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>1.1</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.2</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>1.2</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.3</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>1.3</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.4</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>1.4</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.5</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>1.5</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.6</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>1.6</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.7</td>
                                                  <td>Win 98+ / OSX.1+</td>
                                                  <td>1.7</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Mozilla 1.8</td>
                                                  <td>Win 98+ / OSX.1+</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Seamonkey 1.1</td>
                                                  <td>Win 98+ / OSX.2+</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Gecko</td>
                                                  <td>Epiphany 2.20</td>
                                                  <td>Gnome</td>
                                                  <td>1.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Webkit</td>
                                                  <td>Safari 1.2</td>
                                                  <td>OSX.3</td>
                                                  <td>125.5</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Webkit</td>
                                                  <td>Safari 1.3</td>
                                                  <td>OSX.3</td>
                                                  <td>312.8</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Webkit</td>
                                                  <td>Safari 2.0</td>
                                                  <td>OSX.4+</td>
                                                  <td>419.3</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Webkit</td>
                                                  <td>Safari 3.0</td>
                                                  <td>OSX.4+</td>
                                                  <td>522.1</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Webkit</td>
                                                  <td>OmniWeb 5.5</td>
                                                  <td>OSX.4+</td>
                                                  <td>420</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Webkit</td>
                                                  <td>iPod Touch / iPhone</td>
                                                  <td>iPod</td>
                                                  <td>420.1</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Webkit</td>
                                                  <td>S60</td>
                                                  <td>S60</td>
                                                  <td>413</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera 7.0</td>
                                                  <td>Win 95+ / OSX.1+</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera 7.5</td>
                                                  <td>Win 95+ / OSX.2+</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera 8.0</td>
                                                  <td>Win 95+ / OSX.2+</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera 8.5</td>
                                                  <td>Win 95+ / OSX.2+</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera 9.0</td>
                                                  <td>Win 95+ / OSX.3+</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera 9.2</td>
                                                  <td>Win 88+ / OSX.3+</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera 9.5</td>
                                                  <td>Win 88+ / OSX.3+</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Opera for Wii</td>
                                                  <td>Wii</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Nokia N800</td>
                                                  <td>N800</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Presto</td>
                                                  <td>Nintendo DS browser</td>
                                                  <td>Nintendo DS</td>
                                                  <td>8.5</td>
                                                  <td>C/A<sup>1</sup></td>
                                              </tr>
                                              <tr>
                                                  <td>KHTML</td>
                                                  <td>Konqureror 3.1</td>
                                                  <td>KDE 3.1</td>
                                                  <td>3.1</td>
                                                  <td>C</td>
                                              </tr>
                                              <tr>
                                                  <td>KHTML</td>
                                                  <td>Konqureror 3.3</td>
                                                  <td>KDE 3.3</td>
                                                  <td>3.3</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>KHTML</td>
                                                  <td>Konqureror 3.5</td>
                                                  <td>KDE 3.5</td>
                                                  <td>3.5</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Tasman</td>
                                                  <td>Internet Explorer 4.5</td>
                                                  <td>Mac OS 8-9</td>
                                                  <td>-</td>
                                                  <td>X</td>
                                              </tr>
                                              <tr>
                                                  <td>Tasman</td>
                                                  <td>Internet Explorer 5.1</td>
                                                  <td>Mac OS 7.6-9</td>
                                                  <td>1</td>
                                                  <td>C</td>
                                              </tr>
                                              <tr>
                                                  <td>Tasman</td>
                                                  <td>Internet Explorer 5.2</td>
                                                  <td>Mac OS 8-X</td>
                                                  <td>1</td>
                                                  <td>C</td>
                                              </tr>
                                              <tr>
                                                  <td>Misc</td>
                                                  <td>NetFront 3.1</td>
                                                  <td>Embedded devices</td>
                                                  <td>-</td>
                                                  <td>C</td>
                                              </tr>
                                              <tr>
                                                  <td>Misc</td>
                                                  <td>NetFront 3.4</td>
                                                  <td>Embedded devices</td>
                                                  <td>-</td>
                                                  <td>A</td>
                                              </tr>
                                              <tr>
                                                  <td>Misc</td>
                                                  <td>Dillo 0.8</td>
                                                  <td>Embedded devices</td>
                                                  <td>-</td>
                                                  <td>X</td>
                                              </tr>
                                              <tr>
                                                  <td>Misc</td>
                                                  <td>Links</td>
                                                  <td>Text only</td>
                                                  <td>-</td>
                                                  <td>X</td>
                                              </tr>
                                              <tr>
                                                  <td>Misc</td>
                                                  <td>Lynx</td>
                                                  <td>Text only</td>
                                                  <td>-</td>
                                                  <td>X</td>
                                              </tr>
                                              <tr>
                                                  <td>Misc</td>
                                                  <td>IE Mobile</td>
                                                  <td>Windows Mobile 6</td>
                                                  <td>-</td>
                                                  <td>C</td>
                                              </tr>
                                              <tr>
                                                  <td>Misc</td>
                                                  <td>PSP browser</td>
                                                  <td>PSP</td>
                                                  <td>-</td>
                                                  <td>C</td>
                                              </tr>
                                              <tr>
                                                  <td>Other browsers</td>
                                                  <td>All others</td>
                                                  <td>-</td>
                                                  <td>-</td>
                                                  <td>U</td>
                                              </tr>
                                          </tbody>
                                          <tfoot>
                                              <tr>
                                                  <th>Rendering engine</th>
                                                  <th>Browser</th>
                                                  <th>Platform(s)</th>
                                                  <th>Engine version</th>
                                                  <th>CSS grade</th>
                                              </tr>
                                          </tfoot>
                                      </table>
                                  </div>
                                  
                              </div>
                             
                          </div>
                          
                      </div>
                      
                  </div>
                  
              </section>
             
          </div> */}

          

    </>
  )
}

export default PengembangPengajuanBgh
