import React from 'react'
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useState } from 'react';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';
import { refreshTokenRequest } from '../../refreshTokenRequest';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';


const PengembangPengajuanBghTambah = () => {


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });


  const apiUrl = process.env.REACT_APP_API_ENDPOINT;



  const [nomorSuratPermohonan, setNomorSuratPermohonan] = useState('');
  const [namaTaBgh, setNamaTaBgh] = useState('');
  const [optionPerumahanId, setOptionPerumahanId] = useState([]);
  const [optionTipeRumah, setOptionTipeRumah] = useState([]);
  // const [optionJenisDeret, setOptionJenisDeret] = useState([]);


  const [checkboxData, setCheckboxData] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);



  const [selectedOptionPerumahan, setSelectedOptionPerumahan] = useState('');
  const [optionsPerumahan, setOptionsPerumahan] = useState([]);
  const [selectedOptionTipeRumah, setSelectedOptionTipeRumah] = useState('');

  // const [selectedJenisDeret, setSelectedJenisDeret] = useState('');

  const [jenisPerumahan, setJenisPerumahan] = useState([]);
  const [tglPermohonan, setTglPermohonan] = useState([]);

  const { user, setUser } = useContext(AuthContext);
  const [isTokenExpired, setIsTokenExpired] = useState(false);


  const [dokSuratPermohonan, setDokSuratPermohonan] = useState(null);
  const [dokSertifikatPerencanaan, setDokSertifikatPerencanaan] = useState(null);
  // const [dokImbPbgInduk, setDokImbPbgInduk] = useState(null);
  const [dokImbPbgRumahUsulan, setDokImbPbgRumahUsulan] = useState(null);
  // const [dokSiteplanDinas, setDokSiteplanDinas] = useState(null);
  // const [dokSiteplanJalurAir, setDokSiteplanJalurAir] = useState(null);
  // const [dokRks, setDokRks] = useState(null);
  // const [dokBoq, setDokBoq] = useState(null);
  // const [dokDenahRumah, setDokDenahRumah] = useState(null);
  // const [dokTampakDepan, setDokTampakDepan] = useState(null);
  // const [dokTampakBelakang, setTampakBelakang] = useState(null);
  // const [dokPotonganBangunan, setDokPotonganBangunan] = useState(null);
  // const [dokPenilaianBgh, setDokPenilaianBgh] = useState(null);



  // const handleJenisPerumahan = event => {
  //   setSelectedJenisDeret(event.target.value);
  // }

  const handleOptionChangePerumahan = event => {
    setSelectedOptionPerumahan(event.target.value);
  };

  const handleOptionChangeTipeRumah = event => {
    setSelectedOptionTipeRumah(event.target.value);
  };


  let refreshToken = null;

  const navigate = useNavigate()


  useEffect(() => {

    // alert(user.pengajuanbgh_id)

    fetchOptionsFromApiPerumahan()
      .then(data => setOptionsPerumahan(data))
      .catch(error => console.error('Error fetching options:', error));
  }, []);

  useEffect(() => {
    fetchOptionsFromApiTipeRumah()
      .then(data => setOptionTipeRumah(data))
      .catch(error => console.error('Error fetching options:', error));


    fetchOptionsFromApiTipeRumah()
      .then(data => setCheckboxData(data))
      .catch(error => console.error('Error fetching options:', error));

    // alert('perumahan id berubah');
  }, [selectedOptionPerumahan]);



  const fetchOptionsFromApiPerumahan = async () => {
    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();'
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    // var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

    var idPengembang = user.pengembang_id;

    try {
      const response = await axios.get(apiUrl + '/api/igahp/lokasibypengembangid/' + idPengembang, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // console.error('Error get api data...');
      // navigate('/login');
    }
  };

  const handleCheckboxChange = (id) => {
    // Update selectedCheckboxes state based on checkbox changes
    const newSelectedCheckboxes = selectedCheckboxes.includes(id)
      ? selectedCheckboxes.filter((checkboxId) => checkboxId !== id)
      : [...selectedCheckboxes, id];
    setSelectedCheckboxes(newSelectedCheckboxes);
  };


  const fetchOptionsFromApiTipeRumah = async () => {
    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();'
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    var idPerumahan = (selectedOptionPerumahan === '') ? 0 : selectedOptionPerumahan;

    // var idPengembang = user.pengembang_id;

    // console.log('id perumahan = ' + idPerumahan)

    try {
      const response = await axios.get(apiUrl + '/api/igahp/tipeRumahByPerumahanId/' + idPerumahan, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // console.error('Error get api data...');
      // navigate('/login');
    }
  };


  const handleSubmit = async (e, tahapan) => {
    e.preventDefault();
    let refreshToken = null;

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }



    const formData = new FormData();
    formData.append('userId', user.id);
    formData.append('nomorSuratPermohonan', nomorSuratPermohonan);
    formData.append('namaTaBgh', namaTaBgh);
    formData.append('selectedOptionPerumahan', selectedOptionPerumahan);
    formData.append('selectedOptionTipeRumah', selectedOptionTipeRumah);
    // formData.append('selectedOptionTipeRumah', selectedCheckboxes);
    // formData.append('selectedOptionJenisDeret', selectedJenisDeret);

    formData.append('dokSuratPermohonan', dokSuratPermohonan);
    formData.append('dokSertifikatPerencanaan', dokSertifikatPerencanaan);
    // formData.append('dokImbPbgInduk', dokImbPbgInduk);
    formData.append('dokImbPbgRumahUsulan', dokImbPbgRumahUsulan);
    // formData.append('dokSiteplanDinas', dokSiteplanDinas);
    // formData.append('dokSiteplanJalurAir', dokSiteplanJalurAir);
    // formData.append('dokRks', dokRks);
    // formData.append('dokBoq', dokBoq);

    // formData.append('dokDenahRumah', dokDenahRumah);
    // formData.append('dokTampakDepan', dokTampakDepan);
    // formData.append('dokTampakBelakang', dokTampakBelakang);
    // formData.append('dokPotonganBangunan', dokPotonganBangunan);
    // formData.append('dokPenilaianBgh', dokPenilaianBgh);


    formData.append('tglPermohonan', tglPermohonan);
    formData.append('tahapan', tahapan);


    // formData.append('dokAktePendirian', dokAktePendirian);
    // formData.append('dokNib', dokNib);
    // formData.append('dokNpwp', dokNpwp);


    const sUrl = apiUrl + '/api/igahp/simpanPengajuanBgh';

    console.log(sUrl);
    try {
      await fetch(sUrl, {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
           'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          // console.log(data);
          // alert(data.status);
          // navigate(`/igahp/pengembang/PengembangPengajuanBgh`);


          if (data) {

            if (data.status === 'Sukses') {
              Toast.fire({
                icon: 'success',
                title: data.desc
              });
              navigate(`/depan/pengembang/PengembangPengajuanBgh`);

            } else  {

              Toast.fire({
                icon: 'error',
                title: data.desc
              });
            }

          }



        })
        .catch(error => {
          // Handle error
          console.error(error);

          alert(error);
        });

    } catch (error) {
      console.error('An error occurred:', error);

    }


  }

  return (
    <>

      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-4 mt-2">
              <div className="col-sm-6">
                {/* <h1>Form Pengajuan</h1> */}
                <h1 className="text-muted fw-light">FORM<b> PENGAJUAN</b></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Form Pengajuan</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>



        <div>
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* /.tab-pane */}
                  <ul className="nav nav-pills m-auto p-3" id="four-tab" role="tablist">
                    <li className="nav-item col-md-auto">
                      <a className="nav-link" id="data-perencanaan-tab" data-toggle="tab" href="#data-perencanaan" role="tablist" aria-controls="data-pribadi" aria-selected="true">
                        <span className="bs-stepper-circle" style={{ backgroundColor: 'dodgerblue' }}>1 </span>
                        <span className="bs-stepper-label">PERENCANAAN
                        </span>
                      </a>
                    </li>
                    <div className="line" />
                    <li className="nav-item col-md-auto">
                      <a className="nav-link" id="pendidikan-formal-tab" data-toggle="tab" href="#pendidikan-formal" role="tablist" aria-controls="pendidikan-formal" aria-selected="false">
                        <span className="bs-stepper-circle" style={{ backgroundColor: 'dodgerblue' }}>2 </span>
                        <span className="bs-stepper-label">PEMBANGUNAN
                        </span>
                      </a>
                    </li>
                    <li className="nav-item col-md-auto">
                      <a className="nav-link" id="data-pekerjaan-tab" data-toggle="tab" href="#data-pekerjaan" role="tablist" aria-controls="data-pekerjaan" aria-selected="false">
                        <span className="bs-stepper-circle" style={{ backgroundColor: 'dodgerblue' }}>3 </span>
                        <span className="bs-stepper-label">PEMANFAATAN
                        </span></a>
                    </li>
                    <li className="nav-item col-md-auto">
                      <a className="nav-link" id="kelengkapan-dokumen-tab" data-toggle="tab" href="#kelengkapan-dokumen" role="tablist" aria-controls="kelengkapan-dokumen" aria-selected="false">
                        <span className="bs-stepper-circle" style={{ backgroundColor: 'dodgerblue' }}>4 </span>
                        <span className="bs-stepper-label">PEMBONGKARAN
                        </span></a>
                    </li>
                  </ul>
                  {/* /.tab-form */}
                  <div className="card">  
                    <div className="card-body">
                      <div className="col-12">
                        <div className="tab-content p-2">


                          <div className="tab-pane fade active show" id="data-perencanaan" role="tablist" aria-labelledby="data-perencanaan-tab">

                            <form onSubmit={(e) => handleSubmit(e, 1)}>
                              <div className="form-group">
                                <h6 className="m-0">Isi Form</h6>
                                <p className="text-muted mb-0"><small>Isi data tahap perencanaan</small></p>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">


                                  <div className="form-group">
                                    <div className="mb-3">
                                      <label htmlFor="nomorSuratPermohonan" className="form-label">Nomor Surat Permohonan Penilaian Kinerja BGH</label>
                                      <input type="text" className="form-control"
                                        value={nomorSuratPermohonan} onChange={(e) => setNomorSuratPermohonan(e.target.value)}
                                        placeholder='Nomor Surat Permohonan'
                                        require
                                      />
                                    </div>
                                  </div>

                                </div>
                                <div className="col-sm-6">


                                  <div className="form-group">
                                    <div className="mb-3">
                                      <label>Pilih Perumahan</label>
                                      <select className="form-control" value={selectedOptionPerumahan} onChange={handleOptionChangePerumahan}>
                                        <option value="">Select an option</option>
                                        {optionsPerumahan.map(option2 => (
                                          <option key={option2.id} value={option2.id}>
                                            {option2.nama_perumahan}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>



                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-6">

                                  <div className="form-group">
                                    <label >Tanggal Surat Permohonan</label>
                                    <input
                                      className="form-control bg-white"
                                      type="date"
                                      value={tglPermohonan}
                                      onChange={(e) => setTglPermohonan(e.target.value)}
                                      require
                                    />
                                  </div>



                                </div>
                                <div className="col-sm-6">

                                  <div className="form-group">
                                    <div className="mb-3">
                                      <label>Pilih Tipe Unit Rumah</label>
                                      <select className="form-control" value={selectedOptionTipeRumah} onChange={handleOptionChangeTipeRumah}>
                                        <option value="">Select an option</option>
                                        {optionTipeRumah.map(option3 => (
                                          <option key={option3.id} value={option3.id}>
                                            {option3.nama}
                                          </option>
                                        ))}
                                      </select>
                                    </div>                                    
                                  </div>


                                </div>
                              </div>
                              <br />
                              <div className="form-group">
                                <h6 className="m-0">Unggah File</h6>
                                <p className="text-muted mb-0"><small>Unggah File Syarat Pengajuan</small></p>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">


                                  <div className="form-group">
                                    <label htmlFor="exampleInputFile">Surat Permohonan Penilaian Kinerja BGH</label>
                                    <div className="mb-3">
                                      <input
                                        className="form-control form-control"
                                        type="file"
                                        accept=".pdf,.jpg,.jpeg"
                                        onChange={(e) => setDokSuratPermohonan(e.target.files[0])}
                                        require
                                      />
                                    </div>
                                  </div>



                                  <div className="form-group">
                                    <label htmlFor="exampleInputFile">IMB/PBG Rumah Usulan Cap Dinas</label>
                                    <div className="mb-3">
                                      <input
                                        className="form-control form-control"
                                        type="file"
                                        accept=".pdf,.jpg,.jpeg"
                                        onChange={(e) => setDokImbPbgRumahUsulan(e.target.files[0])}
                                        require
                                      />
                                    </div>

                                    <small>File Dokumen harus dalam <b>format .pdf</b>
                                      </small>
                                  </div>

                                </div>
                              </div>
                              <br />
                              <div className="form-group">
                                <button type="submit" className="btn btn-success">
                                  Simpan
                                </button>
                              </div>
                            </form>
                          </div>


                          <div className="tab-pane fade" id="pendidikan-formal" role="tablist" aria-labelledby="pendidikan-formal-tab">
                            <div className="form-group">
                              <h6 className="mb-0">Data</h6>
                              <small>Isi form tahap pembangunan</small>
                            </div>
                            --body content--
                          </div>


                          <div className="tab-pane fade" id="data-pekerjaan" role="tablist" aria-labelledby="data-pekerjaan-tab">
                            <div className="form-group">
                              <h6 className="mb-0">Data</h6>
                              <small>Isi form tahap pemanfaatan</small>
                            </div>
                            --body content--
                          </div>

                          <div className="tab-pane fade" id="kelengkapan-dokumen" role="tablist" aria-labelledby="kelengkapan-dokumen-tab">
                            <div className="form-group">
                              <h6 className="mb-0">Data</h6>
                              <small>Isi form tahap pembongkaran</small>
                            </div>
                            --body content--
                          </div>
                        </div>
                      </div>
                      {/* /.card */}
                    </div>
                  </div>
                  {/* /.tab-content */}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.card */}
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
          <br />
        </div>


      </div>
      {/* /.content-wrapper */}

    </>
  )
}

export default PengembangPengajuanBghTambah
