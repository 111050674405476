import React from 'react'
import NavBarAtas from './NavBarAtas';
import FooterFront from './FooterFront';

const CatalogDetail = () => {

    require("./../../css/common.css");
    return (
        <>
            <div>
                <NavBarAtas page="1" showButton={true} />
                <section className="wrapper-detail-katalog">
                    <div className="wrapper-history">
                        <a href="/">
                            <div className="btn-circle-green">
                                <img src="images/frontcatalog/ic_arrow_left.svg" alt />
                            </div>
                        </a>
                        <div className="wrapper-list-history">
                            <div className="one-green-xs-regular neutral-60-text">Home</div>
                            <div className="one-green-xs-regular neutral-60-text">/</div>
                            <div className="one-green-xs-regular neutral-60-text">Katalog</div>
                            <div className="one-green-xs-regular neutral-60-text">/</div>
                            <div className="one-green-xs-regular green-main-text">Besi Beton</div>
                        </div>
                        <div className="history-back-mobile">Kembali</div>
                    </div>
                    <div className="wrapper-detail-katalog-konten">
                        <div className="wrapper-image-material-detail-katalog">
                            <div className="wrapper-image-material-detail-katalog-item">
                                <img src="images/frontcatalog/image_besi_beton.png" />
                            </div>
                            {/* <div className="wrapper-list-image-material-detail-katalog">
                                <div className="wrapper-image-material-detail-katalog-item">
                                    <img src="images/frontcatalog/image_besi_beton.png" />
                                </div>
                                <div className="wrapper-image-material-detail-katalog-item">
                                    <img src="images/frontcatalog/image_besi_beton.png" />
                                </div>
                                <div className="wrapper-image-material-detail-katalog-item">
                                    <img src="images/frontcatalog/image_besi_beton.png" />
                                </div>
                            </div> */}
                        </div>
                        <div className="wrapper-deskripsi-material-detail-katalog">
                            <div className="deskripsi-material-title">
                                <div className="one-green-subheading-semi-bold black-text">Besi Beton</div>
                                <div className="badge-certified-green">
                                    <img src="images/perumahandetail/ic_certified_leaf_green.svg" />
                                    <div className="one-green-xs-medium green-main-text">Green Certified</div>
                                </div>
                            </div>
                            <div className="wrapper-detail-material-katalog">
                                <div className="detail-material-item">
                                    <div className="one-green-m-regular neutral-60-text">Ukuran</div>
                                    <div className="one-green-m-regular neutral-60-text">:</div>
                                    <div className="one-green-m-regular neutral-90-text">1 m diameter 0.05 m</div>
                                </div>
                                <div className="detail-material-item">
                                    <div className="one-green-m-regular neutral-60-text">Bahan</div>
                                    <div className="one-green-m-regular neutral-60-text">:</div>
                                    <div className="one-green-m-regular neutral-90-text">Besi Beton</div>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className="wrapper-detail-deskripsi-text">
                                <div className="one-green-m-regular neutral-60-text">Deskripsi</div>
                                <div className="one-green-m-regular neutral-90-text">-</div>
                            </div>
                            <div className="divider-neutral-30" />
                        </div>
                    </div>
                </section>
                <section className="wrapper-list-material-detail-katalog">
                    <div className="one-green-h3-semi-bold">Bahan Lainnya Sesuai Kategori</div>
                    <div className="wrapper-detail-katalog-list-material">
                        <div className="wrapper-material-item">
                            <img src="images/frontcatalog/image_batu_kali.png" width={150} height={150} />
                            <div className="one-green-s-medium black-text">Batu Kali</div>
                        </div>
                        <div className="wrapper-material-item">
                            <img src="images/frontcatalog/image_semen_iso.png" width={150} height={150} />
                            <div className="one-green-s-medium black-text">Semen ISO</div>
                        </div>
                        <div className="wrapper-material-item">
                            <img src="images/frontcatalog/image_besi_sloof.png" width={150} height={150} />
                            <div className="one-green-s-medium black-text">Besi Sloof</div>
                        </div>
                        <div className="wrapper-material-item">
                            <img src="images/frontcatalog/image_pavling_block.png" width={150} height={150} />
                            <div className="one-green-s-medium black-text">Paving Block</div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterFront />
        </>
    )
}

export default CatalogDetail
