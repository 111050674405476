import React from 'react';
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';

const ModalPerkotaanDetail = ({ onClose }) => {


    const [dataPerkotaan2, setDataPerkotaan2] = useState(0);
    const [loading, setLoading] = useState(true);
    const { user, setUser } = useContext(AuthContext);

    const [showDetailModal, setShowDetailModal] = useState(false);
    const [detailData, setDetailData] = useState(null);
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [kodeWilayahDetail, setKodeWilayahDetail] = useState(null);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    let refreshToken = null;

    const handleClose = () => {
        onClose();
    };

    let sequenceNumber = 1;



    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (loading) {
            console.log('Use effetc load fetchdata calling.....');
            // fetchDataRoles();
            fetchDataPerkotaanBaru();

        }
        return () => { script.remove(); }

    }, [loading]);


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (loadingDetail) {
            console.log('Use effetc load fetchdata calling.....');
            // fetchDataRoles();
            fetchDataPerkotaanBaruDetail();

        }
        return () => { script.remove(); }

    }, [loadingDetail]);


    const fetchDataPerkotaanBaru = async () => {
        setLoading(true);

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanKotaList', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataPerkotaan2(response.data); // Update state with fetched data

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }


    };

    const fetchDataPerkotaanBaruDetail = async () => {

        setLoadingDetail(true);
        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanKotaListDetail/' + kodeWilayahDetail, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            console.log(response.data)
            setDetailData(response.data)

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingDetail(false);
        }
    }


    const handleOpenDetail = async (kodeWilayah) => {

        setKodeWilayahDetail(kodeWilayah);
        setShowDetailModal(true);
        setLoadingDetail(true);

    };

    const handleCloseDetail = () => {
        setShowDetailModal(false);
        setDetailData(null);
    };

    return (
        <>
            <div className="modal fade" id="ModalPerkotaanDetail" tabIndex={-1} role="dialog"
                aria-labelledby="ModalPerkotaanDetailLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Kota</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="card">
                                    <div className="card-body">
                                        {loading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <table id="example1" className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>NO</th>
                                                        <th>KOTA</th>
                                                        <th>KELURAHAN</th>
                                                        <th>PEMINAT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataPerkotaan2 && dataPerkotaan2.length > 0 ? (
                                                        dataPerkotaan2.map((item) => (
                                                            <tr key={item.nomor}>
                                                                <td>{sequenceNumber++}</td>
                                                                <td>{item.kabkota}</td>
                                                                <td>{item.kelurahan}</td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link"
                                                                        onClick={() => handleOpenDetail(item.kodeWilayah)}
                                                                    >
                                                                        {item.peminat}
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                {dataPerkotaan2 && dataPerkotaan2.length > 0 && (
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="3"><strong>Total</strong></td>
                                                            <td><strong>{dataPerkotaan2.reduce((acc, item) => acc + item.peminat, 0)}</strong></td>
                                                        </tr>
                                                    </tfoot>
                                                )}
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            {/* Detail Modal */}
            {showDetailModal && (
                <div className="modal fade show" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Detail Peminat</h4>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseDetail}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {loadingDetail ? (
                                    <div>Loading details...</div>
                                ) : detailData ? (
                                    <table id="example2" className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>NIK</th>
                                                <th>Nama</th>
                                                <th>Penghasilan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailData.map((detail, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {detail.nik
                                                            ? detail.nik.slice(0, -4) + "****"
                                                            : "N/A"}
                                                    </td>
                                                    <td>{detail.nama}</td>
                                                    <td>
                                                        {detail.penghasilan != null
                                                            ? detail.penghasilan.toLocaleString('id-ID', {
                                                                style: 'currency',
                                                                currency: 'IDR',
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0
                                                            })
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                ) : (
                                    <div>No details available</div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModalPerkotaanDetail;
