import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';

import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import { useNavigate } from 'react-router-dom';

const ChangePasswordModal = ({ show, handleClose, onChangePassword }) => {

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const [passwordLama, setPasswordLama] = useState('');
  const [passwordBaru, setPasswordBaru] = useState('');
  const [passwordBaru2, setPasswordBaru2] = useState('');

  let refreshToken = null;
  const { user } = useContext(AuthContext);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [ setIsTokenExpired] = useState(false);

  const handlePasswordLamaChange = (event) => {
    setPasswordLama(event.target.value);
  };

  const handlePasswordBaru2Change = (event) => {
    setPasswordBaru2(event.target.value);
  };

  const handlePasswordBaruChange = (event) => {
    setPasswordBaru(event.target.value);
  };

  const handlePasswordLamaBlur = async () => {
    if (passwordLama) {
      const loginRequest = {
        username: user.username, // Replace with actual username or get it from state/props
        password: passwordLama
      };
      try {
        const response = await fetch(apiUrl + '/api/auth/cekPassword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(loginRequest)
        });

        if (response.ok) {
          const result = await response.text();
          // alert(result);
        } else {
          const error = await response.text();
          alert(error);
          // setErrorMessage(error)
        }
      } catch (error) {
        console.error('Error:', error);
        // alert('An error occurred. Please try again.');
      }

    }

  };



  useEffect(() => {
    setErrorMessage("");
  }, []);

  const handleFormSubmitPassword = async (event) => {
    event.preventDefault();

    setErrorMessage("");

    if (passwordBaru !== passwordBaru2) {
      setErrorMessage('password baru dan password konfirmasi tidak sama')
      return
    }


    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      // console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      // console.log("Valid token");
      // result = true;
    }





    const loginRequest = {
      username: user.username, // Replace with actual username or get it from state/props
      password: passwordLama
    };


    try {
      const response = await fetch(apiUrl + '/api/auth/cekPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginRequest)
      });

      if (response.ok) {
        const result = await response.text();
        // alert(result);
      } else {
        const error = await response.text();
        alert(error);
        return
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
      return
    }


    //--------------------------------------------------------------------------------------------------------------------

    var formData = new FormData();
    formData.append("id", user.id);
    formData.append("newPassword", passwordBaru2);

    try {
      await fetch(apiUrl + '/api/igahp/akunchangepassword', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        },
        body: formData  // Set formData directly as the body
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Parse JSON response
        })
        .then(data => {
          // Handle API response
          // setIsLoading(true);

          alert(data.status);
          handleClose();
          // handleNilaiBalikan();
        })
        .catch(error => {
          // Handle fetch errors
          console.error('Fetch error:', error);
          alert('An error occurred while fetching data');
        });

    } catch (error) {
      // Handle other errors
      console.error('An error occurred:', error);
    }


    // alert('password');
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Rubah Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmitPassword}>
          <Form.Group controlId="currentPassword">
            <Form.Label>Password Sekarang</Form.Label>
            <Form.Control
              type="password"
              // placeholder="Enter current password"
              value={passwordLama}
              onChange={handlePasswordLamaChange}
              onBlur={handlePasswordLamaBlur}
              required
            />
          </Form.Group>
  
          <Form.Group controlId="newPassword">
            <Form.Label>Password Baru</Form.Label>
            <Form.Control
              id="passwordBaru"
              type="password"
              placeholder=""
              className="form-control"
              value={passwordBaru}
              onChange={handlePasswordBaruChange}
              required
            />
          </Form.Group>
  
          <Form.Group controlId="confirmPassword">
            <Form.Label>Password Baru Konfirmasi</Form.Label>
            <Form.Control
              id="passwordBaru2"
              type="password"
              placeholder=""
              className="form-control"
              value={passwordBaru2}
              onChange={handlePasswordBaru2Change}
              required
            />
          </Form.Group>
  
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
  
          <Button variant="primary" type="submit" className="mt-3">
            Rubah Password
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
  
};

export default ChangePasswordModal;
