import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { AuthContext } from "../../AuthContext";
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import EXIF from 'exif-js';
import exportCSV from 'json-to-csv-export';

import LocationSelect from '../shared/LocationSelect'
import validator from 'validator';


const NasionalPerkotaan = () => {

    const [judulModal, setJudulModal] = useState(null);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    const [isTokenExpired, setIsTokenExpired] = useState(false);
    
    const { user } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [showModal, setShowModal] = useState(false);


    let refreshToken = null;
    let sequenceNumber = 1;


    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);



    const fetchData = async () => {
        setIsLoading(true);

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanKotaList', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setData(response.data); // Update state with fetched data

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }


    };


    return (
        <>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                


                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Secondary table for transferred data */}
                            
                            {isLoading ? (
                                <p>Loading Data...</p>
                            ) : (
                                <div className="card">
                                    <div className="card-body">
                                        <table id="example1" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>KOTA</th>
                                                    <th>PEMINAT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(data) && data.length > 0 ? (
                                                    data.map((item) => (
                                                        <tr key={item.nomor}>
                                                            <td>{sequenceNumber++}</td>
                                                            <td>{item.kabkota}</td>
                                                            <td>{item.peminat}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>


                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
        </>
    );

};

export default NasionalPerkotaan;

