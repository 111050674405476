import React, { useState } from "react";
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";

// import Badge from "../additional/Bagde";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import ChangePasswordModal from "../shared/ChangePasswordModal"

import Navbar from "./Navbar";
// import $ from "jquery";
import { useEffect } from "react";
// import ContentAsosiasi from "../master/ContentAsosiasi";

const SideBarAdmin = (props) => {
  const { user, setUser } = useContext(AuthContext);

  const [showModalPassword, setshowModalPassword] = useState(false);

  // Fungsi untuk menampilkan modal
  const handleshowModalPassword = () => setshowModalPassword(true);

  // Fungsi untuk menutup modal
  const handleCloseModalPassword = () => setshowModalPassword(false);



  // useEffect(() => {
  //   console.log('sidebar admin component loaded...'); 
  // } );


  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = "/js/highlight.js";
    // script.async = true;
    // document.body.appendChild(script);

    return () => {
      // script.remove();
    };
  }, []);

  return (
    <>


      {/* Main Sidebar Container */}
      {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}

      <aside className="main-sidebar sidebar-light-success elevation-4">


        <Link to="/PengembangAdminDashboard" className="brand-link">
          <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
          <h4><span className="brand-text text-muted ml-2"></span><b>LENTERA</b></h4>
        </Link>

        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
              <a href="#" className="d-block">{user.username}</a>
            </div>
          </div>
          {/* SidebarSearch Form */}
          {/* Add your sidebar search form code here */}
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


              <li className="nav-item">
                <Link to="admin/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>Dashboard</p>
                </Link>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Approval <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="admin/ManajemenUsers" className="nav-link">
                      <i className="far fa-circle nav-icon text-blue"></i>
                      <p>Pengguna</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/ManajemenLokasi" className="nav-link">
                      <i className="far fa-circle nav-icon text-danger"></i>
                      <p>Lokasi Perumahan</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/AdminApprovalPendataan" className="nav-link">
                      <i className="far fa-circle nav-icon text-info"></i>
                      <p>Pendataan</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Data Master <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="admin/ManajemenAsosiasi" className="nav-link">
                      <i className="far fa-circle nav-icon text-blue"></i>
                      <p>Asosiasi</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/ManajemenBanks" className="nav-link">
                      <i className="far fa-circle nav-icon text-danger"></i>
                      <p>Banks</p>
                    </Link>
                  </li>
                </ul>
              </li>


              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Penugasan <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="admin/AdminPenugasanPendataan" className="nav-link">
                      <i className="far fa-circle nav-icon text-blue"></i>
                      <p>Pendataan</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="admin/ManajemenBanks" className="nav-link">
                      <i className="far fa-circle nav-icon text-danger"></i>
                      <p>Lain</p>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-book"></i>
                  <p>
                    Utility <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    {/* Mengganti Link dengan anchor biasa karena kita tidak berpindah halaman */}
                    <a href="#" className="nav-link" onClick={handleshowModalPassword}>
                      <i className="far fa-circle nav-icon text-blue"></i>
                      <p>Rubah Password</p>
                    </a>
                  </li>
                </ul>
              </li>

              <ChangePasswordModal
                show={showModalPassword}
                handleClose={handleCloseModalPassword}
                // onChangePassword={handleChangePassword}
              />

              <li className="nav-item">
                <Link to="pengembang/dataMinat" className="nav-link">
                  <i className="nav-icon far fa-circle text-warning"></i>
                  <p>Peminatan</p>
                </Link>
              </li>



            </ul>
          </nav>

        </div>

      </aside>

      {/* <Outlet /> */}
    </>
  );
};

export default SideBarAdmin;
