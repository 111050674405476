
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from './MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { AuthContext } from '../../AuthContext';
import { useContext } from 'react';
import ModalMinatRumah from './childs/ModalMinatRumah';
import AdminModalMinatRumah from './../igahp_admin/childs/AdminModalMinatRumah'



const PengembangDataMinat = () => {


    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    // const history = useHistory();


    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();

    const [levelUser, setLevelUser] = useState('');  // Change 'user' to 'admin' as needed


    let refreshToken = null;

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);

    const { loginContext } = useContext(AuthContext);


    const [isModal, setIsModal] = useState(false);


    const [isModalOpen, setModalOpen] = useState(false);


    // Function to open the modal
    const handleModalOpen = () => {
        console.log('Modal Open')
        setModalOpen(true);
    };

    // Function to close the modal
    const handleModalClose = () => {
        // alert('close')        
        console.log('Modal Close')
        setModalOpen(false);
        fetchData();

    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    useEffect(() => {


        // const script = document.createElement('script');
        // script.src = "/js/content.js";
        // script.async = true;
        // document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        // return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        console.log('pengembang id ' + user.pengembang_id);

        try {

            let response = '';
            if (user.roles.includes("ROLE_IGAHP_ADMIN")) {
                response = await axios.get(apiUrl + '/api/igahp/pengembang/getDataMinatAll', {
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    }
                });

            } else {

                response = await axios.get(apiUrl + '/api/igahp/pengembang/getDataMinat/' + user.pengembang_id, {
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    }
                });

            }


            // await Delay(5000);

            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };




    const handleAddMinat = () => {
        // user['pemasaranrumah_state'] = 'add';
        // user['pemasaranrumah_id'] = null
        // loginContext(user);

        // navigate(`/depan/pengembang/PengembangPenugasanTambah`);

        
        if (user.roles.includes("ROLE_IGAHP_ADMIN")) {
            setLevelUser('admin')
        }        else {
            setLevelUser('user')
        }

        console.log(user)
        setModalOpen(true);

    };


    const [items, setItems] = useState(data);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            // Filter out the item to be deleted
            const updatedItems = items.filter(item => item.id !== id);
            setItems(updatedItems);

            // Optionally, call an API to delete the item from the server
            // fetch(`/api/delete/${id}`, {
            //     method: 'DELETE',
            // })
            // .then(response => response.json())
            // .then(data => {
            //     console.log('Item deleted', data);
            // })
            // .catch(error => {
            //     console.error('Error deleting item:', error);
            // });


            var formData = new FormData();
            formData.append("id", id);


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }


            try {
                await fetch(apiUrl + '/api/depan/hapusMinat', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    },
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response
                        // console.log(data);

                        setIsLoading(true);
                        alert(data.status);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                        alert(data.status);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }




            console.log(`Item with id ${id} deleted`);
        }
    };

    let sequenceNumber = 1;

    return (
        <>



            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-4 mt-2">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">DATA <b>MINAT RUMAH</b></h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Data Minat Rumah</li>
                                </ol>
                            </div>
                            {/* /.content-card-body */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">


                                {isLoading ?
                                    <p>Loading Data...</p> :

                                    <div className="card">

                                        <div className="card-header">
                                            <button type="submit" onClick={handleAddMinat}
                                                className="btn btn-success"
                                                data-toggle="modal"
                                                data-target="#modal-minat-rumah"
                                            >Tambah Minat Rumah
                                            </button>

                                            {/* {isModalOpen && <ModalMinatRumah 
                                            onClose={handleModalClose} />} */}


                                            {isModalOpen && (
                                                levelUser === 'admin' ? (
                                                    <AdminModalMinatRumah onClose={handleModalClose} />
                                                ) : (
                                                    <ModalMinatRumah onClose={handleModalClose} />
                                                )
                                            )}


                                            <div className="card-info">
                                                {/* <button type="submit" onClick={handleAddLokasi} className="btn btn-primary">Tambah Lokasi</button> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-12">

                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-header */}
                                        <div className="card-body">
                                            <table id="example1" className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>NO</th>
                                                        <th>NAMA</th>
                                                        <th>NO HANDPHONE</th>
                                                        <th>PENGEMBANG</th>
                                                        <th>PERUMAHAN</th>
                                                        <th>TYPE RUMAH</th>
                                                        <th>HARGA RUMAH</th>
                                                        <th>TANGGAL MINAT</th>

                                                        <th style={{ textAlign: 'center' }}>AKSI</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {data.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{sequenceNumber++}</td>
                                                            <td>{item.namaLengkap}</td>
                                                            <td>{item.handphone}</td>
                                                            <td>{item.namaPengembang}</td>
                                                            <td>{item.namaPerumahan}</td>
                                                            <td>{item.namaTipe}</td>
                                                            <td style={{ textAlign: 'center' }}>{formatter.format(item.harga)}</td>
                                                            <td>{item.tglMinat}</td>


                                                            <td className="project-actions text-right">

                                                                <button type="button" className="btn btn-info btn-sm"
                                                                    onClick={(event) => {
                                                                        user['minat_id'] = item.id;
                                                                        user['nama_lengkap'] = item.namaLengkap;
                                                                        user['handphone'] = item.handphone;
                                                                        user['perumahan_id'] = item.perumahanId;
                                                                        loginContext(user); // isi context                                                                      
                                                                        navigate(`/depan/pengembang/MinatInfo`);
                                                                    }}
                                                                >

                                                                    <i className="fas fa-eye mr-1">
                                                                    </i>
                                                                    View
                                                                </button>


                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => handleDelete(item.id)}
                                                                >
                                                                    <i className="fas fa-trash mr-1"></i>
                                                                    Delete
                                                                </button>


                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }





                            </div>
                        </div>
                    </div>


                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}


        </>
    )
}

export default PengembangDataMinat
