import React from 'react'
import Footer from '../Footer'
import { useState ,useEffect} from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';
import MenuPerumahanDetail from './MenuPerumahanDetail';
import axios from 'axios';


const PengembangKantorPemasaran = () => {

    const { user, setUser } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const { loginContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [formData, setFormData] = useState({
        alamat: '',
        nomor: '',
        kodeWilayah: '',
        noTelp: '',
        email: '',
        website: '',
        fax: '',
        isKantorUtama: false,
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl+'/api/depan/getPemasaranRumah/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };



    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
        setFormData({ ...formData, kodeWilayah: villageId });
    };

    const [selectedVillage, setSelectedVillage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the data to be sent to the API
        const requestData = {
            alamat: formData.alamat,
            nomor: formData.nomor,
            kodeWilayah: formData.kodeWilayah,
            noTelp: formData.noTelp,
            email: formData.email,
            website: formData.website,
            fax: formData.fax,
            isKantorUtama: formData.isKantorUtama,
        };



        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        // Send formData to API
        await fetch(apiUrl+'/api/depan/pengembang/lokasi/simpanKantorPemasaran', {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                // Handle API response
                console.log(data);
                alert(data.status);
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });



    };

    const handleAddPemasaranRumah = () => {
        user['pemasaranrumah_state'] = 'add';
        user['pemasaranrumah_id'] = null
        loginContext(user);

        navigate(`/depan/pengembang/PengembangKantorPemasaranTambah`);
    };



    const handleKantorPemasaran = () => {
        ;

        navigate(`/depan/pengembang/PengembangKantorPemasaran`);
    };


    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {

            var formData = new FormData();
            formData.append("id", id);


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }


            try {
                await fetch(apiUrl+'/api/depan/hapusBlokRumah', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    },
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response
                        // console.log(data);

                        setIsLoading(true);
                        alert(data.status);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                        alert(data.status);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }


        } else {

        }
    }

    let sequenceNumber = 1;

    return (
        <>


            {/* Main Sidebar Container */}
            <aside className="main-sidebar sidebar-light-success elevation-4">

                <MenuPerumahanDetail />
            </aside>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">

                        <div className="row mb-4 mt-2">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">KANTOR<b> PEMASARAN</b></h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Kantor Pemasaran</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">



                    <div className="container-fluid">


                        <div className="row">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <button type="submit" onClick={handleAddPemasaranRumah} className="btn btn-primary">Tambah Kantor Pemasaran</button>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            {isLoading ?
                                <p>Loading Data...</p> :

                                <div className="card">

                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <table id="example1" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>ALAMAT</th>
                                                    <th>EMAIL</th>
                                                    <th>TELP</th>
                                                    <th>KANTOR UTAMA</th>
                                                    <th>WEB</th>
                                                    <th>AKSI</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {data.map((item) => (
                                                    <tr key={item.id}>
                                                        
                                                        <td>{sequenceNumber++}</td>
                                                        <td>{item.alamat}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.noTelp}</td>
                                                        <td>

                                                            <input
                                                                type="checkbox"
                                                                checked={item.kantorUtama}
                                                                // onChange={() => handleCheckboxChange(item.id)}
                                                                onChange={() => {
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{item.website}</td>

                                                        <td>
                                                            <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                                onClick={(event) => {
                                                                    // user['perumahan_id'] = item.id;
                                                                    // user['perumahan_nama'] = item.nama_perumahan;                                                                        
                                                                    // loginContext(user); // isi context                                                                        
                                                                    // navigate(`/depan/pengembang/PengembangLokasiProfile`);
                                                                }}
                                                            >
                                                                <i className="fas fa-eye" />
                                                            </button>

                                                            <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                                onClick={(event) => {
                                                                    user['pemasaranrumah_id'] = item.id;
                                                                    user['pemasaranrumah_state'] = 'edit';
                                                                    loginContext(user);
                                                                    navigate(`/depan/pengembang/PengembangKantorPemasaranTambah`);
                                                                }}
                                                            >
                                                                <i className="fas fa-edit" id={`i-${item.id}`} />

                                                                {/* <TambahLokasi/> */}
                                                            </button>

                                                            <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                                <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }


                        </div>



                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}





        </>
    )
}

export default PengembangKantorPemasaran
