import React from 'react';
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';

const ModalPedesaanDetail = ({ onClose }) => {


    const [dataPedesaan2, setDataPedesaan2] = useState(0);

    const [loading, setLoading] = useState(true);

    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    let refreshToken = null;

    const handleClose = () => {
        // Lakukan apa pun yang diperlukan sebelum menutup modal
        // ...
        // Panggil fungsi onClose untuk memberi tahu parent bahwa modal ditutup
        onClose();
    };

    let sequenceNumber = 1;



    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (loading) {
            console.log('Use effetc load fetchdata calling.....');
            // fetchDataRoles();
            fetchDataPedesaanBaru();

        }
        return () => { script.remove(); }

    }, [loading]);

    const fetchDataPedesaanBaru = async () => {
        setLoading(true);

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPimpinanDesaList', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataPedesaan2(response.data); // Update state with fetched data

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }


    };

    return (
        <>

            <div className="modal fade" id="ModalPedesaanDetail" tabIndex={-1} role="dialog"
                aria-labelledby="ModalPedesaanDetailLabel" aria-hidden="true" data-backdrop="static">


                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Desa</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="card">
                                    <div className="card-body">
                                        {loading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <table id="example1" className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>NO</th>
                                                        <th>KABUPATEN</th>
                                                        <th>DESA</th>
                                                        <th>PEMINAT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataPedesaan2 && dataPedesaan2.length > 0 ? (
                                                        dataPedesaan2.map((item) => (
                                                            <tr key={item.nomor}>
                                                                <td>{sequenceNumber++}</td>
                                                                <td>{item.kabkota}</td>
                                                                <td>{item.kelurahan}</td>
                                                                <td>{item.peminat}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="3"><strong>Total</strong></td>                                                        
                                                        <td><strong>{dataPedesaan2.reduce((acc, item) => acc + item.peminat, 0)}</strong></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>



                    </div>
                </div>

            </div>

        </>
    );
}

export default ModalPedesaanDetail;
