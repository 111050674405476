import React, { useState, useEffect } from 'react';
        import axios from 'axios';

const LocationSelect2 = ({ onVillageChange, onProvinceChange, onCityChange, onDistrictChange, villageCode }) => {
    const [provinces2, setprovinces2] = useState([]);
    const [cities2, setcities2] = useState([]);
    const [districts2, setdistricts2] = useState([]);
    const [villages2, setvillages2] = useState([]);

    const [provSelectedOption2, setprovSelectedOption2] = useState('');
    const [kotaSelectedOption2, setkotaSelectedOption2] = useState('');
    const [kecSelectedOption2, setkecSelectedOption2] = useState('');
    const [kelSelectedOption2, setkelSelectedOption2] = useState('');

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        fetchprovinces2();
    }, []);

    useEffect(() => {
        initialSelected();
    }, [villageCode]);

    const initialSelected = async () => {
        try {
            if (villageCode) {
                setprovSelectedOption2(villageCode.substring(0, 2));
                fetchcities2(villageCode.substring(0, 2));
                setkotaSelectedOption2(villageCode.substring(0, 4));
                fetchdistricts2(villageCode.substring(0, 4));
                setkecSelectedOption2(villageCode.substring(0, 6));
                fetchvillages2(villageCode.substring(0, 6));
                setkelSelectedOption2(villageCode);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchprovinces2 = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getProvinsi`);
            setprovinces2(response.data);
        } catch (error) {
            console.error('Error fetching provinces2:', error);
        }
    };

    const fetchcities2 = async (provinceId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKabKota/${provinceId}`);
            setcities2(response.data);
        } catch (error) {
            console.error('Error fetching cities2:', error);
        }
    };

    const fetchdistricts2 = async (cityId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKecamatan/${cityId}`);
            setdistricts2(response.data);
        } catch (error) {
            console.error('Error fetching districts2:', error);
        }
    };

    const fetchvillages2 = async (districtId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKelurahan/${districtId}`);
            setvillages2(response.data);
        } catch (error) {
            console.error('Error fetching villages2:', error);
        }
    };

    const handleProvinceChange = (e) => {
        const selectedProvinceId = e.target.value;
        setprovSelectedOption2(selectedProvinceId);
        fetchcities2(selectedProvinceId);
        setdistricts2([]);
        setvillages2([]);
        if (onProvinceChange) {
            onProvinceChange(selectedProvinceId);
        }
    };

    const handleCityChange = (e) => {
        const selectedCityId = e.target.value;
        setkotaSelectedOption2(selectedCityId);
        fetchdistricts2(selectedCityId);
        setvillages2([]);
        if (onCityChange) {
            onCityChange(selectedCityId);
        }
    };

    const handleDistrictChange = (e) => {
        const selectedDistrictId = e.target.value;
        setkecSelectedOption2(selectedDistrictId);
        fetchvillages2(selectedDistrictId);
        if (onDistrictChange) {
            onDistrictChange(selectedDistrictId);
        }
    };

    const handleVillageChange = (e) => {
        const selectedVillageId = e.target.value;
        setkelSelectedOption2(selectedVillageId);
        if (onVillageChange) {
            onVillageChange(selectedVillageId);
        }
    };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '20px' }}>
            <select onChange={handleProvinceChange} className="form-control" value={provSelectedOption2}>
                <option>Pilih Provinsi</option>
                {provinces2.map((province) => (
                    <option key={province.kode} value={province.kode}>{province.nama_wilayah}</option>
                ))}
            </select>

            <select onChange={handleCityChange} disabled={cities2.length === 0} className="form-control" value={kotaSelectedOption2}>
                <option value="">Pilih Kota/Kabupaten</option>
                {cities2.map((city) => (
                    <option key={city.kode} value={city.kode}>{city.nama_wilayah}</option>
                ))}
            </select>

            <select onChange={handleDistrictChange} disabled={districts2.length === 0} className="form-control" value={kecSelectedOption2}>
                <option value="">Pilih Kecamatan</option>
                {districts2.map((district) => (
                    <option key={district.kode} value={district.kode}>{district.nama_wilayah.toUpperCase()}</option>
                ))}
            </select>

            <select onChange={handleVillageChange} disabled={villages2.length === 0} className="form-control" value={kelSelectedOption2}>
                <option value="">Pilih Kelurahan</option>
                {villages2.map((village) => (
                    <option key={village.kode} value={village.kode}>{village.nama_wilayah.toUpperCase()}</option>
                ))}
            </select>
        </div>
    );
};

export default LocationSelect2;

/*

cara pakai :

import React, { useState } from 'react';
import LocationSelect from './LocationSelect';

const ParentComponent = () => {
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedVillage, setSelectedVillage] = useState('');

    return (
        <div>
            <h1>Location Selector</h1>
            <LocationSelect 
                onProvinceChange={setSelectedProvince} 
                onCityChange={setSelectedCity} 
                onDistrictChange={setSelectedDistrict} 
                onVillageChange={setSelectedVillage} 
            />

            <div>
                <h2>Selected Values</h2>
                <p>Province: {selectedProvince}</p>
                <p>City: {selectedCity}</p>
                <p>District: {selectedDistrict}</p>
                <p>Village: {selectedVillage}</p>
            </div>
        </div>
    );
};

export default ParentComponent;

*/