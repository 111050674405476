import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
// import BrowserDetection from './BrowserDetection';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import NavBarAtas from './NavBarAtas';
import FooterFront from './FooterFront';
import PerumahanDetail from './PerumahanDetail';
// import ReactDOM from 'react-dom';
// import { useHistory } from 'react-router-dom'; // assuming you're using React Router
// import { coilState } from '../../coilState';
// import { useRecoilState } from 'recoil';
// import * as ReactDOMClient from "react-dom/client";
// import Carousel from "react-simply-carousel";


const Product = () => {

  // const [coilData, setCoilData] = useRecoilState(coilState);
  // const navigate = useNavigate();

  // const { user, setUser } = useContext(AuthContext);
  const [user, setUser] = useState({});


  const { loginContext } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const [dataSusun, setDataSusun] = useState([]);

  // const [itemData, setItemData] = useState(null);
  // const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);



  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');



  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  const handleButtonClick = async () => {
    try {
      // Assuming you have an API endpoint and the appropriate method (POST, PUT, etc.)
      // const response = await fetch('http://localhost:8080/api/public/kirimEmailPertanyaan', {
      const response = await fetch('https://onegreen-be-cms.indonesiahijau.id/api/public/kirimEmailPertanyaan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: email,
          isi: question,
          subject: 'contact us'
        }),
      });

      // Handle the response accordingly (e.g., show success message, error handling)
      if (response.ok) {
        console.log('Request successful');
        alert('Pesan telah terkirim, harap tunggu balasan dari admin');
        // You can add further actions here upon successful API request
      } else {
        console.error('Error in API request');
        alert('Error in API request');
        // Handle error cases here
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error');
      // Handle other errors such as network issues
    } finally {

    }
  };





  useEffect(() => {


    if (isLoading || isTokenExpired) {
      fetchDataTapak();
      fetchDataRusun();
    }


  }, []);

  const fetchDataTapak = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanTapak', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setData(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };


  const fetchDataRusun = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanSusun', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setDataSusun(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };

  const goToPerumahanDetail = (id) => {

    user['perumahan_id'] = id;
    loginContext(user); // isi context

  };

  const [activeSlide, setActiveSlide] = useState(0);


  const [active, setActive] = useState('tab-1');

  const handleClick = (event) => {
    setActive(event.target.id);
  };


  require("./../../css/common.css");

  return (

    <>

      <div>

        <NavBarAtas showButton={true} />

        <section className="body-home-content-product">


          <div className="section-headlines-header-text">

            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">Bangunan Gedung Hijau</div>
              <div className="igahp-l-regular black-text">Bangunan hijau, atau sering juga disebut bangunan berkelanjutan, adalah bangunan yang dirancang dan dibangun dengan mempertimbangkan dampak lingkungan seminimal mungkin. Konsep ini mencakup seluruh siklus hidup bangunan, mulai dari perencanaan, pembangunan, pengoperasian, hingga pembongkaran.</div>
            </div>
            <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">Bangunan yang memperoleh sertifikasi ini harus memenuhi kriteria seperti efisiensi energi, konservasi air, pengembangan lokasi yang tepat guna, penggunaan material daur ulang dan pengelolaan sampah, kualitas udara dan kenyamanan yang baik, serta pengelolaan lingkungan gedung.</div>

            </div>


          </div>

          <section className="section-body">


            <div className="body-card-panduan">
              {/* <div className="heading-text">
                <div className="igahp-highlight-semi-bold">Minat Bangunan Bersertifikat Hijau?</div>
              </div>
              <div className="sub-label">
                <div className="igahp-l-regular black-text">Bangunan bersertifikasi hijau sesuai dengan Skema IGAHP </div>
              </div> */}

              <div className="tab-content-product-certificate">
                <div className="group-tab-slider">
                  <div className={`button-tab-slider-default ${active === 'tab-1' ? 'active' : ''}`}
                    id="tab-1"
                    onClick={handleClick}
                  >
                    Perumahan
                  </div>
                  <div className={`button-tab-slider-default ${active === 'tab-2' ? 'active' : ''}`}
                    id="tab-2"
                    onClick={handleClick}
                  >
                    Rusun
                  </div>
                  <div className={`button-tab-slider-default ${active === 'tab-3' ? 'active' : ''}`}
                    id="tab-3"
                    onClick={handleClick}
                  >
                    Swadaya
                  </div>
                </div>
                
                  <div className={`tab-page ${active === 'tab-1' ? 'active' : ''}`}>

                    {/* <div className="group-card"> */}

                    {(!isLoading) && data ? (

                      <div className="group-card">

                        {data.map((item, index) => (
                          <div className="card-image-product" key={item.id}>

                            <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>


                              <div className="image-property">
                                <img
                                  src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                                  alt="gambar properti"

                                  className="image-child"
                                />
                              </div>

                              <div className="card-information">
                                <div className="property-detail-information">
                                  <div className="property-tags">
                                    <div className="info-tags">
                                      <div className="unit-subsidi">{item.totalUnitSubsidiHijau} Unit Subsidi</div>
                                    </div>
                                  </div>
                                  <div className="container-label-info">
                                    <div className="house-title">
                                      <div className="perumahan-griya-asri">{item.nama_perumahan}</div>
                                    </div>
                                    <div className="house-address">
                                      <div className="cikande-banten">{item.kecamatan}</div>
                                    </div>
                                  </div>
                                  <div className="container-label-info">
                                    <div className="house-address">
                                      <div className="developer-name">{item.namaPengembang}</div>
                                    </div>
                                    <div className="wrapper-house-id">
                                      {item.idLokasiString}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </Link>
                          </div>
                        ))}

                      </div>

                    ) : (
                      // Render alternative content if dataExist is false
                      <div>Loading...</div>
                    )}

                    {/* </div> */}

                  </div>

                  <div className={`tab-page ${active === 'tab-2' ? 'active' : ''}`}>

                    {/* <div className="group-card"> */}

                    {(!isLoading) && dataSusun ? (

                      <div className="group-card">

                        {dataSusun.map((item, index) => (
                          <div className="card-image-product" key={item.id}>

                            <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>
                              <div className="image-property">
                                <img
                                  src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                                  alt="gambar properti"

                                  className="image-child"
                                />
                              </div>

                              <div className="card-information">
                                <div className="property-detail-information">
                                  <div className="property-tags">
                                    <div className="info-tags">
                                      <div className="unit-subsidi">{item.totalUnitSubsidiHijau} Unit Subsidi</div>
                                    </div>
                                  </div>
                                  <div className="container-label-info">
                                    <div className="house-title">
                                      <div className="perumahan-griya-asri">{item.nama_perumahan}</div>
                                    </div>
                                    <div className="house-address">
                                      <div className="cikande-banten">{item.kecamatan}</div>
                                    </div>
                                  </div>
                                  <div className="container-label-info">
                                    <div className="house-address">
                                      <div className="developer-name">{item.namaPengembang}</div>
                                    </div>
                                    <div className="wrapper-house-id">
                                      {item.idLokasiString}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </Link>
                          </div>
                        ))}

                      </div>

                    ) : (
                      // Render alternative content if dataExist is false
                      <div>Loading...</div>
                    )}

                    {/* </div> */}

                  </div>

                  <div className="tabs-content">
                  <div className={`tab-page ${active === 'tab-3' ? 'active' : ''}`}>
                    <p>No Data</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">LAPORKAN!!</div>
                    <div className="support-text-attention">
                      <p>Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan</p>
                      <p>Kementerian Perumahan dan Kawasan Permukiman</p></div>

                  </div>
                </div>
              </div>

            </div>

          </section>


        </section>

        <section className="wrapper-contact-us">
          <div className="wrapper-contact-us-content">
            <div className="wrapper-contact-us-left">
              <div className="igahp-contribute">
                <img src="/logo_igahp_white.png" alt="logo igahp" />
              </div>
            </div>
            <div className="wrapper-contact-us-right">

              <p className="igahp-h3-medium white-text" id="contact-us-title">Hubungi kami untuk mengetahui IGAHP</p>
              <div className="wrapper-contact-us-form-email">
                <img src="/ic_message_contact_us.svg" alt="" id="ic-message-contact-us" />
                <input
                  type="text"
                  placeholder="Masukkan email Anda"
                  className="form-rad-12 igahp-m-medium"
                  id="form-contact-us-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>


              <textarea
                placeholder="Masukkan pertanyaan"
                className="form-rad-12 igahp-m-medium"
                id="form-contact-us-question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <button className="btn-green-solid-pressed" id="btn-send-contact-us" type="button" onClick={handleButtonClick} disabled>
                <img src="/ic_send.svg" alt="Send" />
                <div className="igahp-m-semi-bold white-text">Kirim Pertanyaan</div>
              </button>

            </div>
          </div>
          {/* <img src="bg_top_right_contact_us.svg" id="bg-top-right-contact-us" /> */}
          {/* <img src="/ic_green_leaf_testimoni.svg" id="ic-bottom-left-contact-us" width="200" /> */}
        </section>

        <FooterFront />
      </div>

    </>



  )
}

export default Product
