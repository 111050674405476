/* eslint-disable react/jsx-no-comment-textnodes */

import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import BrowserDetection from './BrowserDetection';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import NavBarAtas from './NavBarAtas';
import FooterFront from './FooterFront';
import PerumahanDetail from './PerumahanDetail';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom'; // assuming you're using React Router
import { coilState } from '../../coilState';
import { useRecoilState } from 'recoil';
import * as ReactDOMClient from "react-dom/client";
// import Carousel from "react-simply-carousel";

// import Carousel from 'react-bootstrap/Carousel';

// import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const Mylanding = () => {

  const [coilData, setCoilData] = useRecoilState(coilState);
  const navigate = useNavigate();

  // const { user, setUser } = useContext(AuthContext);
  const [user, setUser] = useState({});


  const { loginContext } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const [dataSusun, setDataSusun] = useState([]);

  // const [itemData, setItemData] = useState(null);
  // const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');



  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  const handleButtonClick = async () => {

    try {
      // Assuming you have an API endpoint and the appropriate method (POST, PUT, etc.)
      // const response = await fetch('http://localhost:8080/api/public/kirimEmailPertanyaan', {
      const response = await fetch('https://onegreen-be-cms.indonesiahijau.id/api/public/kirimEmailPertanyaan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: email,
          isi: question,
          subject: 'contact us'
        }),
      });

      // Handle the response accordingly (e.g., show success message, error handling)
      if (response.ok) {
        console.log('Request successful');
        alert('Pesan telah terkirim, harap tunggu balasan dari admin');
        // You can add further actions here upon successful API request
      } else {
        console.error('Error in API request');
        alert('Error in API request');
        // Handle error cases here
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error');
      // Handle other errors such as network issues
    } finally {

    }
  };



  useEffect(() => {


    if (isLoading || isTokenExpired) {
      fetchDataTapak();
      fetchDataRusun();
    }


  }, []);

  const fetchDataTapak = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanTapak', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setData(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };


  const fetchDataRusun = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanSusun', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setDataSusun(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };

  const goToPerumahanDetail = (id) => {

    user['perumahan_id'] = id;
    loginContext(user); // isi context

  };

  const [activeSlide, setActiveSlide] = useState(0);


  const [active, setActive] = useState('tab-1');

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  // const content accordion content
  const [imageSource, setImageSource] = useState("images/aplikasi/image_phone_aplikasi_1.svg");


  const [isActivePenilaian, setIsActivePenilaian] = useState(false);
  const handlePenilaianOtomatisClick = () => {
    if (isActivePenilaian) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    }
    setIsActivePenilaian(!isActivePenilaian);
    setIsActiveUnggah(false);
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveUnggah, setIsActiveUnggah] = useState(false);
  const handleUnggahOtomatisClick = () => {
    if (isActiveUnggah) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_2.svg";
      setImageSource(newImageSource);
    }
    setIsActiveUnggah(!isActiveUnggah);
    setIsActivePenilaian(false)
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveTerbit, setIsActiveTerbit] = useState(false);
  const handleTerbitOtomatisClick = () => {
    if (isActiveTerbit) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_3.svg";
      setImageSource(newImageSource);
    }
    setIsActiveTerbit(!isActiveTerbit)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
    setIsActivePenugasan(false);
  };


  const [isActivePenugasan, setIsActivePenugasan] = useState(false);
  const handlePenugasanOtomatisClick = () => {
    if (isActivePenugasan) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_4.svg";
      setImageSource(newImageSource);
    }
    setIsActivePenugasan(!isActivePenugasan)
    setIsActiveTerbit(false)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
  };


  // const content catalog
  const [isActivePondasi, setIsActivePondasi] = useState(true);
  const handlePondasiClick = () => {
    setIsActivePondasi(!isActivePondasi);
    setIsActiveDinding(false)
    setIsActiveKusen(false)
    setIsActiveAtap(false)
    setIsActiveLedeng(false)
    setIsActiveElektrik(false);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(false)
  };

  const [isActiveDinding, setIsActiveDinding] = useState(false);
  const handleDindingClick = () => {
    setIsActivePondasi(false)
    setIsActiveDinding(!isActiveDinding)
    setIsActiveKusen(false)
    setIsActiveAtap(false)
    setIsActiveLedeng(false)
    setIsActiveElektrik(false);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(false)
  };

  const [isActiveKusen, setIsActiveKusen] = useState(false);
  const handleKusenClick = () => {
    setIsActivePondasi(false)
    setIsActiveDinding(false)
    setIsActiveKusen(!isActiveKusen)
    setIsActiveAtap(false)
    setIsActiveLedeng(false)
    setIsActiveElektrik(false);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(false)

  };

  const [isActiveAtap, setIsActiveAtap] = useState(false);
  const handleAtapClick = () => {
    setIsActivePondasi(false)
    setIsActiveDinding(false)
    setIsActiveKusen(false)
    setIsActiveAtap(!isActiveAtap)
    setIsActiveLedeng(false)
    setIsActiveElektrik(false);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(false)

  };


  const [isActiveLedeng, setIsActiveLedeng] = useState(false);
  const handleLedengClick = () => {
    setIsActivePondasi(false)
    setIsActiveDinding(false)
    setIsActiveKusen(false)
    setIsActiveAtap(false)
    setIsActiveLedeng(!isActiveLedeng)
    setIsActiveElektrik(false);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(false)

  };

  const [isActiveElektrik, setIsActiveElektrik] = useState(false);
  const handleElektrikClick = () => {
    setIsActivePondasi(false)
    setIsActiveDinding(false)
    setIsActiveKusen(false)
    setIsActiveAtap(false)
    setIsActiveLedeng(false)
    setIsActiveElektrik(!isActiveElektrik);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(false)

  };


  const [isActiveMaterial, setIsActiveMaterial] = useState(false);
  const handleMaterialClick = () => {
    setIsActivePondasi(false)
    setIsActiveDinding(false)
    setIsActiveKusen(false)
    setIsActiveAtap(false)
    setIsActiveLedeng(false)
    setIsActiveElektrik(false);
    setIsActiveMaterial(!isActiveMaterial)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(false)

  };

  const [isActiveFurnitur, setIsActiveFurnitur] = useState(false);
  const handleFurniturClick = () => {

    setIsActivePondasi(false)
    setIsActiveDinding(false)
    setIsActiveKusen(false)
    setIsActiveAtap(false)
    setIsActiveLedeng(false)
    setIsActiveElektrik(false);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(!isActiveFurnitur)
    setIsActiveDekorasi(false)

  };


  const [isActiveDekorasi, setIsActiveDekorasi] = useState(false);
  const handleDekorasiClick = () => {

    setIsActivePondasi(false)
    setIsActiveDinding(false)
    setIsActiveKusen(false)
    setIsActiveAtap(false)
    setIsActiveLedeng(false)
    setIsActiveElektrik(false);
    setIsActiveMaterial(false)
    setIsActiveFurnitur(false)
    setIsActiveDekorasi(!isActiveDekorasi)

  };


  require("./../../css/common.css");



  return (

    <>
      {/* <BrowserDetection /> */}
      <div>

        <NavBarAtas page="0" showButton={true} />

        <section className="body-home">
          <div className="section-home-content">
            <div className="wrapper-home-content">
              <div className="igahp-hero-title-bold green-pressed-text">INDONESIA GREEN AFFORDABLE HOUSING PROGRAM</div>

            </div>
            <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">IGAHP merupakan transisi penyediaan perumahan bagi Masyarakat Berpenghasilan Rendah (MBR) yang menerapkan prinsip Bangunan Gedung Hijau (BGH) untuk mencapai target perubahan iklim dengan mengurangi emisi karbon.</div>
              <div className="wrapper-button-house-information">

                <a href="/panduan" className="igahp-m-medium">
                  <div className="btn-green-solid-pressed">Lihat panduan</div></a>
                <a href="/igahpdetail" className="igahp-m-medium">
                  <div className="btn-secondary-white">Baca selengkapnya</div></a>
                {/* <div className="btn-secondary-white igahp-m-medium">Baca Selengkapnya</div> */}

              </div>
            </div>
          </div>

          <div className="section-img-contribute">
            <div className="igahp-contribute">
              <img src="/logo_igahp_grey.png" alt="logo collab" />
              {/* <img src="/logo_hreis.png" alt="logo collab" /> */}
              <img src="/logo_hreis_2.png" alt="logo collab" />
              <img src="/logo_rumahqu.png" alt="logo collab" />
            </div>
          </div>

          <div className="section-hero-image-landing">
            <div className="section-hero-image-landing img">
              <video autoPlay muted loop>
                <source src="/video-hero-image.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="section-green-house-objective">
              <div className="igahp-h2-semi-bold black-text" id="green-house-objective-title">Rumah Hijau dirancang untuk mengurangi dampak negatif pada lingkungan dan meningkatkan kualitas hidup penghuninya.</div>
              <div className="igahp-l-regular black-text" id="green-house-objective-description">Merujuk laporan Climate Transparency 2021, <b>bangunan gedung di Indonesia mengeluarkan emisi karbon sebesar 4,6%</b>, emisi langsung yang berasal dari pembakaran alat penghangat, memasak, dan lain-lain serta 24,5% emisi tidak langsung dari aktivitas jaringan listrik untuk peralatan rumah tangga. Secara umum, Indonesia masih menjadi <b>salah satu negara penghasil emisi karbon terbesar dunia,</b> yakni menempati <b>peringkat ke-6 dengan total emisi sebesar 691,97 juta ton CO2.</b></div>
              {/* <img src="/ic_tree.svg" id="green-house-objective-tree" /> */}
            </div>
          </div>


          <section className="section-body">
            <div className="body-card">
              <div className="heading-text">
                <div className="igahp-highlight-semi-bold">Produk IGAHP</div>
              </div>
              <div className="sub-label">
                <div className="igahp-l-regular black-text">Bantuan Dana Pembiayaan yang terjangkau untuk masyarakat</div>
              </div>


              <div className="group-card">

                <div className="card-product">
                  <div className="support-text">
                    <div className="igahp-m-regular">
                      Bantuan pembangunan atau peningkatan kualitas Rumah Swadaya melalui kredit atau pembiayaan oleh Bank Pelaksana
                      dengan bukti kepemilikan berupa sertipikat hak milik atau sertipikat hak guna bangunan.
                    </div>
                  </div>
                  <div className="label-product">
                    <div className="igahp-h2-semi-bold">
                      Pembiayaan
                      <br />
                      Perumahan Swadaya
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="card-product">
                  <div className="support-text">
                    <div className="igahp-m-regular">
                      Bantuan pembangunan atau peningkatan kualitas Rumah Swadaya melalui kredit atau pembiayaan oleh Bank Pelaksana dengan bukti penguasaan tanah berupa girik, letter C, petok D, pipil tanah, rincik, /buku penguasaan lainnya.
                    </div>
                  </div>
                  <div className="label-product">
                    <div className="igahp-h2-semi-bold">
                      Pembiayaan
                      <br />
                      Perumahan Mikro
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                </div>

                <div className="card-product">
                  <div className="support-text">
                    <div className="igahp-m-regular">
                      Bantuan untuk pemenuhan sebagian atau seluruh biaya konstruksi Rumah yang memenuhi Standar Teknis Bangunan Gedung Hijau Klas Bangunan 1a pada Debitur/Nasabah KPR Sejahtera atau KPR Tapera.
                    </div>
                  </div>
                  <div className="label-product">
                    <div className="igahp-h2-semi-bold">
                      Pembiayaan
                      <br />
                      Konstruksi Rumah
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                </div>

              </div>
            </div>


            <div className="body-card">
              <div className="heading-text">
                <div className="igahp-highlight-semi-bold">Minat Bangunan Bersertifikat Hijau?</div>
              </div>
              <div className="sub-label">
                <div className="igahp-l-regular black-text">Bangunan bersertifikasi hijau sesuai dengan Skema IGAHP </div>
              </div>

              <button className="btn-green-solid-pressed" disabled>
                <div className="igahp-m-semi-bold white-text">Lihat lebih banyak</div>
                <img src="/igahp/ic_arrow_right_white.svg" alt="" />
              </button>

              <div className="tab-content-product-certificate">
                <div className="group-tab-slider">
                  <div className={`button-tab-slider-default ${active === 'tab-1' ? 'active' : ''}`}
                    id="tab-1"
                    onClick={handleClick}
                  >
                    Perumahan
                  </div>
                  <div className={`button-tab-slider-default ${active === 'tab-2' ? 'active' : ''}`}
                    id="tab-2"
                    onClick={handleClick}
                  >
                    Rusun
                  </div>
                  <div className={`button-tab-slider-default ${active === 'tab-3' ? 'active' : ''}`}
                    id="tab-3"
                    onClick={handleClick}
                  >
                    Swadaya
                  </div>
                </div>

                <div className={`tab-page ${active === 'tab-1' ? 'active' : ''}`}>

                  {/* <div className="group-card"> */}

                  {(!isLoading) && data ? (

                    <div className="group-card">

                      {data.map((item, index) => (
                        <div className="card-image-product" key={item.id}>

                          <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>


                            <div className="image-property">
                              <img
                                src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                                alt="gambar properti"

                                className="image-child"
                              />
                            </div>

                            <div className="card-information">
                              <div className="property-detail-information">
                                <div className="property-tags">
                                  <div className="info-tags">
                                    <div className="unit-subsidi">{item.totalUnitSubsidiHijau} Unit Subsidi</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-title">
                                    <div className="perumahan-griya-asri">{item.nama_perumahan}</div>
                                  </div>
                                  <div className="house-address">
                                    <div className="cikande-banten">{item.kecamatan}</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-address">
                                    <div className="developer-name">{item.namaPengembang}</div>
                                  </div>
                                  <div className="wrapper-house-id">
                                    {item.idLokasiString}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Link>
                        </div>
                      ))}

                    </div>

                  ) : (
                    // Render alternative content if dataExist is false
                    <div>Loading...</div>
                  )}

                  {/* </div> */}

                </div>


                <div className={`tab-page ${active === 'tab-2' ? 'active' : ''}`}>

                  {/* <div className="group-card"> */}

                  {(!isLoading) && dataSusun ? (

                    <div className="group-card">

                      {dataSusun.map((item, index) => (
                        <div className="card-image-product" key={item.id}>

                          <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>
                            <div className="image-property">
                              <img
                                src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                                alt="gambar properti"

                                className="image-child"
                              />
                            </div>

                            <div className="card-information">
                              <div className="property-detail-information">
                                <div className="property-tags">
                                  <div className="info-tags">
                                    <div className="unit-subsidi">{item.totalUnitSubsidiHijau} Unit Subsidi</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-title">
                                    <div className="perumahan-griya-asri">{item.nama_perumahan}</div>
                                  </div>
                                  <div className="house-address">
                                    <div className="cikande-banten">{item.kecamatan}</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-address">
                                    <div className="developer-name">{item.namaPengembang}</div>
                                  </div>
                                  <div className="wrapper-house-id">
                                    {item.idLokasiString}
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Link>
                        </div>
                      ))}

                    </div>

                  ) : (
                    // Render alternative content if dataExist is false
                    <div>Loading...</div>
                  )}

                  {/* </div> */}

                </div>


                <div className="tabs-content">
                  <div className={`tab-page ${active === 'tab-3' ? 'active' : ''}`}>
                    <p>No Data</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="body-card-katalog">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="igahp-h2-semi-bold">Katalog Bahan Material</div>
                    <div className="support-text-attention">
                      Lihat bahan material bangunan yang disarankan</div>



                  </div>
                </div>
              </div>

              <section className="wrapper-konten-katalog-depan">
                <div className="wrapper-konten-katalog-title">
                  <div className="igah-l-medium neutral-60-text">KATEGORI</div>
                  <div className="wrapper-choose-category-mobile">
                    <div className="one-green-s-regular neutral-90-text">Struktur &amp; Pondasi</div>
                    <img src="images/aplikasi/ic_chevron_down.svg" />
                  </div>
                </div>

                <div className="wrapper-katalog-material-depan">

                  <div className="wrapper-kategori-katalog">

                    <div className={`kategori-katalog-item ${isActivePondasi ? 'selected' : ''}`} id="kategori-struktur-pondasi" onClick={handlePondasiClick}>
                      <div className="kategori-katalog-title" >
                        <div className="one-green-s-regular">Struktur &amp; Pondasi</div>
                        <svg className="chevron-kategori" width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveDinding ? 'selected' : ''}`} id="kategori-dinding" onClick={handleDindingClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Dinding</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveKusen ? 'selected' : ''}`} id="kategori-kusen-ventilasi" onClick={handleKusenClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Kusen &amp; Ventilasi</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveAtap ? 'selected' : ''}`} id="kategori-struktur-atap" onClick={handleAtapClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Struktur Atap</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveLedeng ? 'selected' : ''}`} id="kategori-ledeng-saniter" onClick={handleLedengClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Ledeng &amp; Saniter</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveElektrik ? 'selected' : ''}`} id="kategori-elektrik" onClick={handleElektrikClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Elektrik</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveMaterial ? 'selected' : ''}`} id="kategori-material-bangunan" onClick={handleMaterialClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Material Bangunan</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveFurnitur ? 'selected' : ''}`} id="kategori-furnitur" onClick={handleFurniturClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Furnitur</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>
                    <div className={`kategori-katalog-item ${isActiveDekorasi ? 'selected' : ''}`} id="kategori-dekorasi" onClick={handleDekorasiClick}>
                      <div className="kategori-katalog-title">
                        <div className="one-green-s-regular">Dekorasi</div>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                        </svg>
                      </div>
                    </div>

                  </div>



                  <div className="wrapper-katalog-list-material">


                    {isActivePondasi && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_batu_kali.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Batu Kali</div>
                          </div>
                        </a>
                        <a href="/catalogdetail" target="blank">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_besi_beton.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Besi Beton</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_semen_iso.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Semen ISO</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_besi_sloof.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Besi Sloof</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_pavling_block.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Paving Block</div>
                          </div>
                        </a>
                      </>
                    )}

                    {isActiveDinding && (
                      <>
                        <a href="/CatalogDetail2" target="blank">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_hollow_block.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Hollow Block</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_bata_merah.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Bata Merah</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_gypsum.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Gypsum</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_triplek.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Triplek</div>
                          </div>
                        </a>
                      </>
                    )}

                    {isActiveKusen && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_kayu.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Kayu</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_alumunium_kusen.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Alumunium Kusen</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_jendela.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Jendela</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_roster.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Roster</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_pintu.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Pintu</div>
                          </div>
                        </a>
                      </>
                    )}


                    {isActiveAtap && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_baja_ringan.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Baja Ringan</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_genteng_tanah_liat.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Genteng Tanah Liat</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_baja_wf.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Baja WF</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_beton.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Beton</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_metal_pasir_hijau.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Metal Pasir Hijau</div>
                          </div>
                        </a>
                      </>
                    )}

                    {isActiveLedeng && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_meteran_air.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Meteran Air</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_shower.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Shower</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_wastafel.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Wastafel</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_closet.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Closet</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_pipa_pvc.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Pipa PVC</div>
                          </div>
                        </a>
                      </>
                    )}

                    {isActiveElektrik && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_kabel_listrik.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Kabel Listrik</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_meteran_listrik.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Meteran Listrik</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_mcb.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">MCB</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_stopkontak.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Stopkontak</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_bohlam.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Bohlam</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_lampu_taman_tenaga_surya.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Lampu Taman Tenaga Surya</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_saklar.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Saklar</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_panel_surya.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Panel Surya</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_lampu_sel.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Lampu Foto Sel</div>
                          </div>
                        </a>
                      </>
                    )}

                    {isActiveMaterial && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_cat_biru.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Cat Biru</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_cat_coklat.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Cat Coklat</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_ubin_stately_pearl_grey.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Ubin Stately Pearl Grey</div>
                          </div>
                        </a>
                      </>
                    )}

                    {isActiveFurnitur && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_lemari.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Lemari</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_meja.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Meja</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_kursi.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Kursi</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_buffet.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Buffet</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_kabinet.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Kabinet</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_sofa.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Sofa</div>
                          </div>
                        </a>
                      </>
                    )}

                    {isActiveDekorasi && (
                      <>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_jam_dinding.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Jam Dinding</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_bingkai_foto.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Bingkai Foto</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_tanaman_amarilis_merah.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Tanaman Amarilis Merah</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_tempat_tisu_anyaman_batik.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Tempat Tisu Anyaman Batik</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_gorden_hitam.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Gorden Hitam</div>
                          </div>
                        </a>
                        <a href="#">
                          <div className="wrapper-material-item-katalog">
                            <img src="images/frontcatalog/image_cermin.png" width={88} height={88} />
                            <div className="one-green-s-medium black-text">Cermin</div>
                          </div>
                        </a>
                      </>
                    )}

                  </div>


                </div>
              </section>

            </div>

            <div className="body-card">
              <div className="content-sertifikasi-bgh">
                <div className="header-text">
                  <div className="igahp-h2-semi-bold">Sertifikasi Bangunan Hijau</div>
                  {/* <div className="supporting-text">Sertifikasi penilaian teknis bangunanan gedung hijau </div> */}
                </div>
                <div className="group-card">
                  <div className="card-product">
                    <img className="igahp-contribute" alt="" src="/ic_logo_bgh.png" />
                    <div className="supporting-text">
                      <div className="igahp-s-regular">
                        <p>Sertifikasi <b>Bangunan Gedung Hijau (BGH)</b> adalah sertifikasi yang diberikan kepada bangunan yang memenuhi kriteria lingkungan dan keberlanjutan tertentu. Sertifikasi ini bertujuan untuk mendorong pembangunan bangunan yang ramah lingkungan dan berkelanjutan di Indonesia.</p>
                        {/* <br></br> */}
                        <p>Beberapa kriteria yang harus dipenuhi untuk mendapatkan sertifikasi BGH, antara lain: Efisiensi energi, Konservasi air, Pengelolaan limbah, Penggunaan material ramah lingkungan.</p>
                        <br></br>
                        <p>Sertifikat BGH berlaku selama 5 tahun dan diterbitkan secara elektronik melalui Sistem Informasi Manajemen Bangunan Gedung. Penerbitan sertifikat BGH tidak dipungut biaya.</p>
                      </div>
                    </div>
                    {/* <div className="btn-secondary-white igahp-m-medium">Baca Selengkapnya</div> */}
                  </div>
                  <div className="card-product">
                    <img className="igahp-contribute" alt="" src="/ic_edge.png" />
                    <div className="supporting-text">
                      <div className="igahp-s-regular">
                        <p>Sertifikasi <b>EDGE</b> adalah sistem sertifikasi bangunan hijau yang bertujuan untuk membuat bangunan lebih hemat sumber daya. EDGE merupakan inovasi dari International Finance Corporation (IFC), anggota Grup Bank Dunia.</p>
                        <p>EDGE merupakan singkatan dari Excellence in Design for Greater Efficiencies. Sertifikasi ini bertujuan untuk mengurangi dampak lingkungan dari bangunan dalam tiga area, yaitu: Konsumsi energi langsung, Konsumsi air, Jejak energi dari bahan konstruksi.</p>
                        <br></br>
                        <p>Proyek yang mendapatkan sertifikasi EDGE dapat menghemat minimal 20% di tiga kategori sumber daya.</p>
                      </div>
                    </div>
                    {/* <div className="btn-secondary-white igahp-m-medium">Baca Selengkapnya</div> */}
                  </div>
                  <div className="card-product">
                    <img className="igahp-contribute" alt="" src="/ic_greenship.png" />
                    <div className="supporting-text">
                      <div className="igahp-s-regular">
                        <p>Sertifikasi <b>Greenship</b> adalah sistem penilaian bangunan hijau di Indonesia yang dikelola oleh Green Building Council Indonesia (GBCI). Sertifikasi ini bertujuan untuk mendorong pembangunan properti yang berkelanjutan dan ramah lingkungan.</p>
                        <p>Sertifikasi ini bertujuan untuk mendorong pembangunan berkelanjutan dan ramah lingkungan pada sektor properti di Indonesia.</p>
                        <br></br>
                        <p>Sertifikasi Greenship dapat digunakan sebagai bukti bahwa sebuah perusahaan memiliki bangunan yang hemat energi, hemat air, hemat gas rumah kaca, serta konservasi jalan</p>
                      </div>
                    </div>
                    {/* <div className="btn-secondary-white igahp-m-medium">Baca Selengkapnya</div> */}
                  </div>
                </div>
              </div>



            </div>

            <section className="wrapper-green-house-testimoni">
              <p className="igahp-h3-semi-bold black-text" id="green-house-testimoni-title">Kata Mereka Tentang Rumah Hijau</p>
              <div className="wrapper-testimoni-list">

                <div className="wrapper-testimoni-item">
                  <div className="wrapper-user-testimoni">
                    <img src="ic_user_avatar_pak_herry.png" alt="" width="54" height="54" />
                    <div className="wrapper-testimoni-content">
                      <img src="ic_double_quotes.svg" alt="" height="14" width="14" />
                      <div className="igahp-m-regular black-text">IGAHP sebagai langkah konkrit di sektor perumahan, sebagai sebuah platform untuk memfasilitasi transisi ke arah perumahan hijau yang mencakup penguatan sisi supply dan juga aspek pembiayaannya. ASEAN Chairmanship</div>
                      <img src="ic_double_quotes.svg" alt="" height="14" width="14" />
                    </div>
                  </div>
                  <div className="wrapper-user-info">
                    <div className="igahp-subheading-semi-bold black-text">Herry Trisaputra Zuna</div>
                    <div className="igahp-s-regular neutral-60-text">Dirjen Pembiayaan Infrastruktur Pekerjaan Umum dan Perumahan, Kementerian PUPR - 2021 s.d 2024
                    </div>
                  </div>
                  {/* <img src="ic_green_leaf_testimoni.svg" id="green_leaf_testimoni" width="48" height="48" /> */}
                </div>
                <div className="wrapper-testimoni-item">
                  <div className="wrapper-user-testimoni">
                    <img src="ic_user_avatar_pak_haryo.png" alt="" width="54" height="54" />
                    <div className="wrapper-testimoni-content">
                      <img src="ic_double_quotes.svg" alt="" height="14" width="14" />
                      <div className="igahp-m-regular black-text">Sekarang kita sudah masuk agenda global bahwa kita harus mengusung konsep <i>green</i>. Ini salah satu inisiasi pemerintah dan Kementerian PUPR untuk menyediakan rumah terjangkau dan ramah lingkungan.</div>
                      <img src="ic_double_quotes.svg" alt="" height="14" width="14" />
                    </div>
                  </div>
                  <div className="wrapper-user-info">
                    <div className="igahp-l-semi-bold black-text">Haryo Bekti Martoyoedo</div>
                    <div className="igahp-s-regular neutral-60-text">Direktur PPP, Dirjen Pembiayaan Infrastruktur Pekerjaan Umum dan Perumahan - Kementerian PUPR</div>
                  </div>

                  {/* <img src="ic_green_leaf_testimoni.svg" id="green_leaf_testimoni" width="48" height="48" /> */}
                </div>
                <div className="wrapper-testimoni-item">
                  <div className="wrapper-user-testimoni">
                    <img src="ic_user_avatar_bu_srimulyani.png" alt="" width="54" height="54" />
                    <div className="wrapper-testimoni-content">
                      <img src="ic_double_quotes.svg" alt="" height="14" width="14" />
                      <div className="igahp-m-regular black-text">Kami turut senang mendengar peluncuran program dari Kementerian PUPR untuk 1 juta rumah dan retrofit dengan konsep green housing. ASEAN Chairmanship.</div>
                      <img src="ic_double_quotes.svg" alt="" height="14" width="14" />
                    </div>
                  </div>
                  <div className="wrapper-user-info">
                    <div className="igahp-l-semi-bold black-text">Sri Mulyani Indrawati</div>
                    <div className="igahp-s-regular neutral-60-text">Menteri Keuangan RI</div>
                  </div>

                  {/* <img src="ic_green_leaf_testimoni.svg" id="green_leaf_testimoni" width="48" height="48" /> */}
                </div>

                {/* <div className="wrapper-testimoni-item">
              <div className="wrapper-user-testimoni">
              <img src="ic_user_avatar_pak_putu.png" width="54" height="54" />
                <div className="wrapper-testimoni-content">
                <img src="ic_double_quotes.svg" height="14" width="14" />
                <div className="igahp-m-regular neutral-90-text">Kami berharap pembangunan perumahan subsidi tersebut dapat membantu mengurangi angka <i>backlog</i> rumah yang masih cukup tinggi di Kabupaten Kuningan.</div>
                <img src="ic_double_quotes.svg" height="14" width="14" />
              </div>
              </div>
              <div className="wrapper-user-info">
              <div className="igahp-subheading-semi-bold black-text">I Putu Bagiasna</div>
              <div className="igahp-xs-regular neutral-60-text">Kepala Dinas DPKPP</div>
                </div>
             
              <img src="ic_green_leaf_testimoni.svg" id="green_leaf_testimoni" width="48" height="48" />
            </div> */}

              </div>
            </section>

          </section>

          <section className="section-body">
            <div className="body-card">

              {/* <img src="images/aplikasi/image_phone_aplikasi_1.svg " id="image-keunggulan-aplikasi" /> */}
              {/* <img src={imageSource} id="image-keunggulan-aplikasi" /> */}


              <div className="wrapper-deskripsi-faq-regulasi">
                <div className="heading-text-regulasi">
                  <div className="igahp-h3-semi-bold">Regulasi</div>
                </div>
                <div className="sub-label">
                  <div className="igahp-m-regular black-text">Kumpulan peraturan perundang-undangan terkait</div>
                </div>
                <div className="wrapper-list-faq-regulasi">

                  <div className={`list-faq-regulasi-item ${isActivePenilaian ? 'active' : ''}`} id="penilaian-otomatis" onClick={handlePenilaianOtomatisClick}>
                    <div className="list-faq-regulasi-title">Peraturan Pemerintah No 16 Tahun 2021</div>
                    <div className="list-faq-regulasi-description">
                      Peraturan Pemerintah No 16 Tahun 2021 tentang Peraturan Pelaksanaan Undang-Undang Nomor 28 Tahun 2002 tentang Bangunan Gedung
                      <div className="btn-container">
                        <div className="btn-unduh-file igahp-s-medium">Unduh file
                          <img src="/ic_download_file.svg" alt=""></img></div>
                      </div>
                    </div>
                    {/* <img src={imageSource} id="image-keunggulan-aplikasi-mobile" /> */}
                    <div className="divider-neutral-30"></div>
                    <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" alt="" width={20} height={20} />

                  </div>

                  <div className={`list-faq-regulasi-item ${isActiveUnggah ? 'active' : ''}`} id="unggah-dokumen" onClick={handleUnggahOtomatisClick}>
                    <div className="list-faq-regulasi-title">Peraturan Menteri PUPR No 21 Tahun 2021</div>
                    <div className="list-faq-regulasi-description">
                      Peraturan Menteri Pekerjaan Umum dan Perumahan Rakyat Nomor 21 Tahun 2021 tentang Penilaian Kinerja Bangunan Gedung Hijau
                      <div className="btn-container">
                        <div className="btn-unduh-file igahp-s-medium">Unduh file
                          <img src="/ic_download_file.svg" alt=""></img></div>
                      </div>
                    </div>
                    {/* <img src={imageSource} id="image-keunggulan-aplikasi-mobile" /> */}
                    <div className="divider-neutral-30" />
                    <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" alt="" width={20} height={20} />
                  </div>

                  <div className={`list-faq-regulasi-item ${isActiveTerbit ? 'active' : ''}`} id="penerbitan-dokumen" onClick={handleTerbitOtomatisClick}>
                    <div className="list-faq-regulasi-title">Surat Edaran Direktur Jenderal Cipta Karya Kementerian PUPR No 03/SE/DC/2023</div>
                    <div className="list-faq-regulasi-description">
                      Petunjuk Teknis Penilaian Kinerja Bangunan Gedung Hijau untuk Klas Bangunan 1a
                      <div className="btn-container">
                        <div className="btn-unduh-file igahp-s-medium">Unduh file
                          <img src="/ic_download_file.svg" alt=""></img></div>
                      </div>
                    </div>
                    <div className="divider-neutral-30" />
                    <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" alt="" width={20} height={20} />
                  </div>

                </div>
              </div>
            </div>
            <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">LAPORKAN!!</div>
                    <div className="support-text-attention">
                      <p>Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan</p>
                      <p>Kementerian Perumahan dan Kawasan Permukiman</p></div>

                  </div>
                </div>
              </div>

            </div>

          </section>

        </section>

        {/* 
                  <div className="card-image-product">
                    <div className="image">
                      <img className="image-child" alt="" src="/igahp/foto_contoh_rumah.png" />
                    </div>
                    <div className="card-information">
                      <div className="property-detail-information">
                        <div className="property-tags">
                          <div className="info-tags">
                            <div className="unit-subsidi">18 Unit Subsidi</div>
                          </div>
                        </div>
                        <div className="container-label-info">
                          <div className="house-title">
                            <div className="perumahan-griya-asri">PERUMAHAN GRIYA ASRI PERTAMA</div>
                          </div>
                          <div className="house-address">
                            <div className="cikande-banten">Cikande, Banten</div>
                          </div>
                        </div>
                        <div className="house-address">
                          <div className="developer-name1">
                            <div className="cikande-banten">PT. INDO MULIA JAYA</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-image-product">
                    <div className="image">
                      <img className="image-child" alt="" src="/igahp/foto_contoh_rumah.png" />
                    </div>
                    <div className="card-information">
                      <div className="property-detail-information">
                        <div className="property-tags">
                          <div className="info-tags">
                            <div className="unit-subsidi">18 Unit Subsidi</div>
                          </div>
                        </div>
                        <div className="container-label-info">
                          <div className="house-title">
                            <div className="perumahan-griya-asri">PERUMAHAN GRIYA ASRI PERTAMA</div>
                          </div>
                          <div className="house-address">
                            <div className="cikande-banten">Cikande, Banten</div>
                          </div>
                        </div>
                        <div className="house-address">
                          <div className="developer-name1">
                            <div className="cikande-banten">PT. INDO MULIA JAYA</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-image-product">
                    <div className="image">
                      <img className="image-child" alt="" src="/igahp/foto_contoh_rumah.png" />
                    </div>
                    <div className="card-information">
                      <div className="property-detail-information">
                        <div className="property-tags">
                          <div className="info-tags">
                            <div className="unit-subsidi">18 Unit Subsidi</div>
                          </div>
                        </div>
                        <div className="container-label-info">
                          <div className="house-title">
                            <div className="perumahan-griya-asri">PERUMAHAN GRIYA ASRI PERTAMA</div>
                          </div>
                          <div className="house-address">
                            <div className="cikande-banten">Cikande, Banten</div>
                          </div>
                        </div>
                        <div className="house-address">
                          <div className="developer-name1">
                            <div className="cikande-banten">PT. INDO MULIA JAYA</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-image-product">
                    <div className="image">
                      <img className="image-child" alt="" src="/igahp/foto_contoh_rumah.png" />
                    </div>
                    <div className="card-information">
                      <div className="property-detail-information">
                        <div className="property-tags">
                          <div className="info-tags">
                            <div className="unit-subsidi">18 Unit Subsidi</div>
                          </div>
                        </div>
                        <div className="container-label-info">
                          <div className="house-title">
                            <div className="perumahan-griya-asri">PERUMAHAN GRIYA ASRI PERTAMA</div>
                          </div>
                          <div className="house-address">
                            <div className="cikande-banten">Cikande, Banten</div>
                          </div>
                        </div>
                        <div className="house-address">
                          <div className="developer-name1">
                            <div className="cikande-banten">PT. INDO MULIA JAYA</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}


        {/* <section className="section-green-house-objective">
          <div className="igahp-h2-semi-bold black-text" id="green-house-objective-title">Rumah Hijau dirancang untuk mengurangi dampak negatif pada lingkungan dan meningkatkan kualitas hidup penghuninya.</div>
          <div className="igahp-l-regular black-text" id="green-house-objective-description">Merujuk laporan Climate Transparency 2021, <b>bangunan gedung di Indonesia mengeluarkan emisi karbon sebesar 4,6%</b>, emisi langsung yang berasal dari pembakaran alat penghangat, memasak, dan lain-lain serta 24,5% emisi tidak langsung dari aktivitas jaringan listrik untuk peralatan rumah tangga. Secara umum, Indonesia masih menjadi <b>salah satu negara penghasil emisi karbon terbesar dunia,</b> yakni menempati <b>peringkat ke-6 dengan total emisi sebesar 691,97 juta ton CO2.</b></div>
          <img src="/igahp/ic_tree.svg" id="green-house-objective-tree" />
        </section> */}
        {/* <section className="wrapper-igahp-partner">
          <div className='igahp-h3-semi-bold black-text'>Bekerjasama dengan</div>
          <div className="igahp-partner">
             <img src="logo_world_bank.png" height={50} />
             <img src="/igahp/logo_pupr.png" height={50} />
            <img src="/igahp/logo_adb.png" height={50} />
            <img src="logo_himbara.png" height={50}/>
          </div>
        </section> */}

        {/* <section className="wrapper-green-house">
          <div className="wrapper-image-green-house">
            <img src="/igahp/image_green_house.png" id="image_green_house" alt />
          </div>
          <div className="wrapper-description-green-house">
            <div className="description-green-house-text">
              <div className="igahp-h1-bold black-text" id="description-green-house-title">Apa itu Rumah Hijau</div>
              <p className="igahp-l-regular black-text" id="description-green-house-desc">Upaya pemerintah dalam implementasi penurunan Net Zero Carbon dengan menciptakan perumahan berlabel Green Housing melalui Program Indonesia Green Affordable Housing Program (IGAHP)</p>
              <p className="igahp-m-regular neutral-90-text" id="description-green-house-desc">Rumah Hijau juga menekankan daya guna kembali sumber daya alam
                yang dapat diperbarui, mengelola dan
                memilah sampah rumah tangga secara mandiri, serta adanya ruang terbuka hijau di tempat tinggal.</p>
              <div className="border-left-bottom-green" />
              <div className="btn-green-solid-pressed igahp-m-medium">Lihat Katalog</div>
            </div>
            <div className="trees-illustration-image">
              <img src="/igahp/ic_tree.svg" alt />
            </div>
          </div>
        </section> */}

        {/* <section className="importance-green-house">
          <div className="importance-green-house-title">
            <div className="igahp-h3-semi-bold black-text" id="importance-green-house-text-title">Keunggulan Rumah Hijau</div>
            {/* <div className="selected-line">
              <div className="left-line" />
              <div className="mid-line" />
              <div className="right-line" />
            </div>
          </div>
          <div className="importance-green-house-description">
            <ul>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_material_ramah_lingkungan.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Material Ramah Lingkungan</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Material bangunan yang digunakan ramah lingkungan dan buatan lokal
                  </p>
                </div>
              </li>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_ruang_terbuka_hijau.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Ruang Terbuka Hijau</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Terdapat area hijau &gt;20% dari luas tanah sehingga dapat menyehatkan sirkulasi udara</p>
                </div>
              </li>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_rumah_sehat.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Rumah Sehat</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Terdapat sirkulasi udara yang baik, pencahayaan
                    matahari yang cukup dan menggunakan saniter hemat air</p>
                </div>
              </li>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_recycle.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Gaya Hidup Ramah Lingkungan</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Adanya pemilahan dan pengelolaan sampah dan
                    limbah rumah tangga yang baik</p>
                </div>
              </li>
            </ul>
          </div>
        </section> */}







        {/* 
        <section className="wrapper-accepted-certificate">
          <div className="importance-green-house-title">
            <div className="igahp-h3-semi-bold black-text centered-align-text">Sertifikasi Rumah Hijau</div>
          
          </div>
          <div className="list-accepted-certificate">
            <div className="accepted-certificate-item">
              <img src="/ic_bgh.png" height="80" alt="" />
              <p className="igahp-l-medium neutral-60-text centered-align-text">BGH Klas 1a</p>
            </div>
            <div className="accepted-certificate-item">
              <img src="/ic_edge.png" height="80" alt="" />
              <p className="igahp-l-medium neutral-60-text centered-align-text">Edge</p>
            </div>
            <div className="accepted-certificate-item">
              <img src="/ic_greenship.png" height="80" alt="" />
              <p className="igahp-l-medium neutral-60-text centered-align-text">Greenship</p>
            </div>
          </div>
        </section> */}



        {/* Section QR App Dihide Dulu
        <section className="mobile-app-qr">
          <div className="mockup-image-mobile">
            <img src="image_mockup_mobile.png" alt />
          </div>
          <div className="image-qr-download-mobile">
            <div className="image-qr-download-title">
              <div className="igahp-h1-bold black-text">DOWNLOAD APLIKASI ONE GREEN MOBILE</div>
              <div className="selected-line">
                <div className="left-line" />
                <div className="mid-line" />
                <div className="right-line" />
              </div>
            </div>
            <div className="image-qr-download-content">
              <div className="scan-qr-text">
                <p className="igahp-m-regular black-text">Silakan scan QR code dengan menggunakan <i>smartphone </i>
                  <b>Android</b> Anda untuk mendownload aplikasi <b>One Green</b> mobile.
                </p>
                <div className="wrapper-compatible-android">
                  <p className="igahp-m-regular neutral-60-text">Kompatibel pada operasi sistem :</p>
                  <img src="ic_android.svg" alt />
                </div>
              </div>
              <div className="image-qr-download">
                <img src="one_green_mobile_qr.png" alt />
              </div>
            </div>
          </div>
        </section> */}



        <section className="wrapper-contact-us">
          <div className="wrapper-contact-us-content">
            <div className="wrapper-contact-us-left">
              <div className="igahp-contribute">
                <img src="/logo_igahp_white.png" alt="logo igahp" />
              </div>
            </div>
            <div className="wrapper-contact-us-right">

              <p className="igahp-h3-medium white-text" id="contact-us-title">Hubungi kami untuk mengetahui IGAHP</p>
              <div className="wrapper-contact-us-form-email">
                <img src="/ic_message_contact_us.svg" alt="" id="ic-message-contact-us" />
                <input
                  type="text"
                  placeholder="Masukkan email Anda"
                  className="form-rad-12 igahp-m-medium"
                  id="form-contact-us-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>


              <textarea
                placeholder="Masukkan pertanyaan"
                className="form-rad-12 igahp-m-medium"
                id="form-contact-us-question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <button className="btn-green-solid-pressed" id="btn-send-contact-us" type="button" onClick={handleButtonClick} disabled>
                <img src="/ic_send.svg" alt="Send" />
                <div className="igahp-m-semi-bold white-text">Kirim Pertanyaan</div>
              </button>

            </div>
          </div>
          {/* <img src="bg_top_right_contact_us.svg" id="bg-top-right-contact-us" /> */}
          {/* <img src="/ic_green_leaf_testimoni.svg" id="ic-bottom-left-contact-us" width="200" /> */}
        </section>
        <FooterFront />
      </div>

    </>



  )
}

export default Mylanding
