import React from 'react'
import Footer from '../Footer'
import { useState } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';

const MenuPerumahanDetail = () => {

    const { user, setUser } = useContext(AuthContext);

    const navigate = useNavigate()


    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const handleKantorPemasaran = () => {
        navigate(`/depan/pengembang/PengembangKantorPemasaran`);
    };


    return (
        <>

            <Link to="/PengembangAdminDashboard" className="brand-link">
                <img src="../../AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-light">{user.perumahan_nama}</span>
            </Link>


            {/* Sidebar */}
            <div className="sidebar">


                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">



                        <li className="nav-item">
                            <Link to="/depan/pengembang/PengembangLokasiProfile" className="nav-link">
                                <i className="nav-icon fas fa-map-pin"></i>
                                <p>Lokasi Perumahan</p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/depan/pengembang/PengembangKantorPemasaran" className="nav-link">
                                <i className="nav-icon fas fa-building"></i>
                                <p>Kantor Pemasaran</p>
                            </Link>
                        </li>



                        <li className="nav-item">
                            <Link to="/depan/pengembang/PengembangLokasiBlok" className="nav-link">
                                <i className="nav-icon fas fa-map-signs"></i>
                                <p>Blok Perumahan</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/depan/pengembang/PengembangLokasiTipeRumah" className="nav-link">
                                <i className="nav-icon fas fa-laptop-house"></i>
                                <p>Tipe Rumah/Unit</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/depan/pengembang/PengembangLokasiSitePlan" className="nav-link">
                                <i className="nav-icon fas fa-object-group"></i>
                                <p>Siteplan Digital</p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/depan/pengembang/LokasiFasilitas" className="nav-link">

                                <i class="nav-icon fas fa-thumbtack me-2 mr-2"></i>
                                <p>Fasilitas</p>
                            </Link>
                        </li>

                        {/* {(user.status_verifikasi === true) ? (
                            <>
                                <li className="nav-item">
                                    <Link to="/depan/pengembang/PengembangLokasiBlok" className="nav-link">
                                        <i className="nav-icon  	fas fa-layer-group"></i>
                                        <p>Blok Perumahan</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/depan/pengembang/PengembangLokasiTipeRumah" className="nav-link">
                                        <i className="nav-icon  	fas fa-laptop-house"></i>
                                        <p>Tipe Rumah/Unit</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/depan/pengembang/PengembangLokasiSitePlan" className="nav-link">
                                        <i className="nav-icon  	 	 	fab fa-figma"></i>
                                        <p>Siteplan Digital</p>
                                    </Link>
                                </li>
                            </>
                        ) : (
                            <></>
                        )} */}



                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </>
    )
}

export default MenuPerumahanDetail
