import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

import ChangePasswordModal from "../shared/ChangePasswordModal"

const Sidebar = () => {
    const { user } = useContext(AuthContext);

    const [showModalPassword, setshowModalPassword] = useState(false);

    // Fungsi untuk menampilkan modal
    const handleshowModalPassword = () => setshowModalPassword(true);

    // Fungsi untuk menutup modal
    const handleCloseModalPassword = () => setshowModalPassword(false);


    // State to handle the collapse of each section
    const [isCollapsed, setIsCollapsed] = useState({
        dataReferensi: false,
        calonPenerima: false,
        pendataan: false,
        bgh: false,
        surveiLapangan: false,
        minatProgram: false,
        slikBank: false,
        penerimaManfaat: false,
        hasilSubsidiChecking: false, // Added for handling submenu under Hasil Subsidi Checking
        utility: false,
    });

    // Function to toggle collapse of a specific menu
    const toggleCollapse = (section) => {
        setIsCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    useEffect(() => {
        // Any additional JavaScript can be loaded here
    }, []);

    return (
        <aside id="control_sidebar" className="main-sidebar sidebar-light-success elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                <h4>
                    <span className="brand-text text-muted ml-2"></span>
                    <b>LENTERA HIJAU</b>
                </h4>
            </a>

            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex flex-wrap">
                    <div className="image">
                        <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                    </div>
                    <div className="info p-0 ml-4">
                        <div>
                            <info className="text-muted">
                                USER PEMDA
                                <a href="#" className="d-block">{user?.username}</a>
                            </info>
                        </div>
                    </div>
                </div>

                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Dashboard */}
                        <li className="nav-item">
                            <Link to="pemda/dashboard" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>Dashboard</p>
                            </Link>
                        </li>

                        {/* Data Referensi */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('dataReferensi')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Data Referensi
                                    <i className={`fas fa-angle-left right ${isCollapsed.dataReferensi ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {/* {isCollapsed.dataReferensi && (                                
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/QuesionerDataAwal" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Awal</p>
                                        </Link>
                                    </li>
                                </ul>                                
                            )} */}
                            {isCollapsed.dataReferensi && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        {/* <Link to="pemda/ListPendataanBgh" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Hasil Sinkronisasi</p>
                                        </Link> */}
                                        <Link to="pemda/QuesionerDataAwal" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Hasil Sinkronisasi</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                            {/* {isCollapsed.dataReferensi && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/ListPendataanBgh" className="nav-link">
                                              <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data RumahQu</p>
                                        </Link>
                                    </li>
                                </ul>                                
                            )} */}
                        </li>

                        {/* Data Calon Penerima Manfaat */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('calonPenerima')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Status Subsidi Checking
                                    <i className={`fas fa-angle-left right ${isCollapsed.calonPenerima ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.calonPenerima && (
                                <ul className="nav nav-treeview">
                                    {/* <li className="nav-item">
                                        <Link to="pemda/IgahpBelumSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Belum Subsidi Checking</p>
                                        </Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link to="pemda/LolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Lolos Checking</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/TidakLolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Tidak Lolos Checking</p>
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="pemda/SubsidiCheckingResume" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Resume Subsidi Checking</p>
                                        </Link>
                                    </li> */}
                                </ul>
                            )}
                        </li>

                        {/* Data Survei Lapangan */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('surveiLapangan')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Data Survei Lapangan
                                    <i className={`fas fa-angle-left right ${isCollapsed.surveiLapangan ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.surveiLapangan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/IgahpDataSiapSurvei" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Siap Survei</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/IgahpDataHasilSurvei" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Data Hasil Survei</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/IgahpDataAnalisaHasilSurvei" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Data Hasil Analisa Survei</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('pendataan')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Admin Pendataan
                                    <i className={`fas fa-angle-left right ${isCollapsed.pendataan ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.pendataan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/PemdaPenugasanPendataan" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Penugasan </p>
                                        </Link>
                                    </li>

                                </ul>
                            )}
                            {isCollapsed.pendataan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/PemdaApprovalPendataan" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Approval</p>
                                        </Link>
                                    </li>

                                </ul>
                            )}
                        </li>

                        {/* <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('bgh')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Bangunan Gedung Hijau
                                    <i className={`fas fa-angle-left right ${isCollapsed.bgh ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.bgh && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/ListPengajuanBgh" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>List Pengajuan BGH</p>
                                        </Link>
                                    </li>

                                </ul>
                            )}
                            
                            {isCollapsed.bgh && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="tabgh/TaBghHasilPenilaian" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Penilaian Hasil BGH</p>
                                        </Link>
                                    </li>

                                </ul>
                            )}
                        </li> */}


                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('utility')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Utility
                                    <i className={`fas fa-angle-left right ${isCollapsed.utility ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.utility && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        {/* Mengganti Link dengan anchor biasa karena kita tidak berpindah halaman */}
                                        <a href="#" className="nav-link" onClick={handleshowModalPassword}>
                                            <i className="far fa-circle nav-icon text-blue"></i>
                                            <p>Rubah Password</p>
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <ChangePasswordModal
                            show={showModalPassword}
                            handleClose={handleCloseModalPassword}
                        // onChangePassword={handleChangePassword}
                        />

                        {/* Data Minat Program */}
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('minatProgram')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Data Minat Program
                                    <i className={`fas fa-angle-left right ${isCollapsed.minatProgram ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.minatProgram && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/ListPendataanBgh" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Hasil Survei</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/LolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Data Approval Pemda</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li> */}

                        {/* Data SLIK Bank */}
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('slikBank')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Data SLIK Bank
                                    <i className={`fas fa-angle-left right ${isCollapsed.slikBank ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.slikBank && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/LolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Data Belum Cek SLIK</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/LolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Lolos SLIK</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/TidakLolosSLIK" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Tidak Lolos SLIK</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li> */}

                        {/* Data Penerima Manfaat */}
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('penerimaManfaat')}>
                                <i className="nav-icon fas fa-copy"></i>
                                <p>
                                    Data Penerima Manfaat
                                    <i className={`fas fa-angle-left right ${isCollapsed.penerimaManfaat ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.penerimaManfaat && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/LolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Penerima Manfaat</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;
