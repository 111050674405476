import React from 'react'
import SurveyorDashboard from './SurveyorDashboard'

const SurveyorContentDepan = () => {
  return (
    <>
      <SurveyorDashboard />
    </>
  )
}

export default SurveyorContentDepan
