import React from 'react';
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';

const ModalPemprovTerkoneksi = ({ onClose }) => {


    const [dataPemprovTerkoneksi, setDataPemprovTerkoneksi] = useState(0);

    const [loading, setLoading] = useState(true);

    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    let refreshToken = null;

    const handleClose = () => {
        // Lakukan apa pun yang diperlukan sebelum menutup modal
        // ...
        // Panggil fungsi onClose untuk memberi tahu parent bahwa modal ditutup
        onClose();
    };

    let sequenceNumber = 1;



    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (loading) {
            console.log('Use effetc load fetchdata calling.....');
            // fetchDataRoles();
            fetchDataPedesaanBaru();

        }
        return () => { script.remove(); }

    }, [loading]);

    const fetchDataPedesaanBaru = async () => {
        setLoading(true);

        try {
            const response = await axios.get(apiUrl + '/api/igahp/dashboard/getPemprovTerkoneksiDetail', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setDataPemprovTerkoneksi(response.data); // Update state with fetched data

        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }


    };

    return (
        <>

            <div className="modal fade" id="ModalPemprovTerkoneksi" tabIndex={-1} role="dialog"
                aria-labelledby="ModalPemprovTerkoneksiLabel" aria-hidden="true" data-backdrop="static">


                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Pemprov Terkoneksi</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="card">
                                    <div className="card-body">
                                        {loading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <table id="example1" className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>NO</th>
                                                        <th>PROVINSI</th>
                                                        <th className="text-end">TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dataPemprovTerkoneksi && dataPemprovTerkoneksi.length > 0 ? (
                                                        dataPemprovTerkoneksi.map((item, index) => (
                                                            <tr key={item.kode}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.namaProv}</td>
                                                                <td className="text-end">{item.total.toLocaleString()}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="3">No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="2"><strong>Total</strong></td>
                                                        <td className="text-end">
                                                            <strong>{dataPemprovTerkoneksi.reduce((acc, item) => acc + item.total, 0).toLocaleString()}</strong>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>



                    </div>
                </div>

            </div>

        </>
    );
}

export default ModalPemprovTerkoneksi;
