import React from 'react'
import PemdaDashboard from './PimpinanDashboard'
import PimpinanDashboard from './PimpinanDashboard'

const PimpinanContentDepan = () => {
  return (
    <>
      <PimpinanDashboard />
    </>
  )
}

export default PimpinanContentDepan
