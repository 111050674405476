import React from 'react'

import Footer from '../Footer';
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
import { AuthContext } from '../../AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import EXIF from 'exif-js';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import CustomIframe from '../CustomIFrame';
import Iframe from 'react-iframe';
import moment from 'moment';
import { useEffect } from 'react';


import Swal from 'sweetalert2';


const ModalLokasiTambah = (onClose, dataMinat) => {


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);

    const [gpsLoaded, setGpsLoaded] = useState(false);

    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const [isButtonProcess, setIsButtonProcess] = useState(false);


    const handleMapContainerReady = () => {
        setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };


    let refreshToken = null;
    const navigate = useNavigate()

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [lokasiGps, setLokasiGps] = useState({});
    const [selectedOption, setSelectedOptionKodeWIlayah] = useState(null);
    const [wilayahTerdekatTampil, setWilayahTerdekatTampil] = useState(false);
    const [namaPerumahan, setNamaPerumahan] = useState('');
    const [jenisPerumahan, setJenisPerumahan] = useState('0');
    const [nomorIMB, setNomorIMB] = useState('');
    const [nikNPWPPemilikIMB, setNikNPWPPemilikIMB] = useState('');
    const [tglTerbitIMB, setTglTerbitIMB] = useState('');
    const [fileUploadIMB, setFileUploadIMB] = useState(null);
    const [fileUploadSitePlan, setFileUploadSitePlan] = useState(null);
    const [fotoGerbang, setFotoGerbang] = useState(null);
    const [fotoGerbangImg, setFotoGerbangImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');
    const [fotoTengah, setFotoTengah] = useState(null);
    const [fotoTengahImg, setFotoTengahImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');
    const [fotoContoh, setFotoContoh] = useState(null);
    const [fotoContohImg, setFotoContohImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');
    const [isLoading, setIsLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [displayGps, setDisplayGps] = useState(false);
    const [fotoSiteplan, setFotoSiteplan] = useState(null);

    const [fotoJalurAir, setFotoJalurAir] = useState(null);


    const [gerbangLongi, setGerbangLongi] = useState('');
    const [gerbangLati, setGerbangLati] = useState('');
    const [tengahLongi, setTengahLongi] = useState('');
    const [tengahLati, setTengahLati] = useState('');
    const [contohLongi, setContohLongi] = useState('');
    const [contohLati, setContohLati] = useState('');

    const [longiTengah, setLongiTengah] = useState('');
    const [latiTengah, setLatiTengah] = useState('');


    useEffect(() => {

        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        console.log(user)

        console.log('-------------------')
        // console.log(user);
        if (user.perumahan_state == 'edit') {
            console.log('masuk edit')
            fetchData();
        } else {
            console.log('masuk tambah data')
        }


        return () => { script.remove(); }


    }, []);



    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);



    const handleClose = () => {
        onClose();
    };

    const defaultImgSrc = apiUrl + '/dist/img/no-photo-choose.png';

    const handleImageError = (event) => {
        // If the original image fails to load, set the source to the default image
        event.target.src = defaultImgSrc;
    };

    const handleJenisPerumahan = (event) => {
        setJenisPerumahan(event.target.value);
        //  alert(jenisPerumahan);5
    };

    const handleOptionChange = (event) => {
        setSelectedOptionKodeWIlayah(event.target.value);
        // alert(event.target.value);
    };


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            let id = 1;
            const response = await axios.get(apiUrl + '/api/depan/lokasi/detail/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log('info detail lokasi for edit ')
            console.log(response.data)

            setNamaPerumahan(response.data.nama_perumahan);
            setJenisPerumahan(response.data.jenis_perumahan)
            setNomorIMB(response.data.imb);
            // setTglTerbitIMB(response.data.tgl_imb)

            setTglTerbitIMB(moment(response.data.tgl_imb).format('YYYY-MM-DD'))

            setNikNPWPPemilikIMB(response.data.pemilik_imb)

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const handleFotoGerbang = (event) => {

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setFotoGerbangImg(reader.result);
        };
        reader.readAsDataURL(file);

        setFotoGerbang(event.target.files[0]);

        // Check if the file is an image
        if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }

        EXIF.getData(event.target.files[0], function () {
            // Check if GPS data exists
            const lat = EXIF.getTag(this, 'GPSLatitude');
            const latRef = EXIF.getTag(this, 'GPSLatitudeRef');
            const lon = EXIF.getTag(this, 'GPSLongitude');
            const lonRef = EXIF.getTag(this, 'GPSLongitudeRef');

            if (lat === undefined || lon === undefined) {
                alert('Image does not contain GPS data.');
                setWilayahTerdekatTampil(false);
                setOptions([]);
                return;
            }
            var latitude = convertDMSToDD(lat, latRef);
            var longitude = convertDMSToDD(lon, lonRef);

            setGerbangLongi(longitude);
            setGerbangLati(latitude)
            // setLokasiGps({ lat: latitude, lng: longitude });
            // populateRadioButton(latitude, longitude);

        })
    }

    const handleFotoTengah = (e) => {

        const file = e.target.files[0];


        const reader = new FileReader();

        reader.onload = () => {
            setFotoTengahImg(reader.result);
        };

        reader.readAsDataURL(file);

        setFotoTengah(e.target.files[0]);

        if (!file || !file.type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }

        EXIF.getData(file, function () {
            // Check if GPS data exists
            const lat = EXIF.getTag(this, 'GPSLatitude');
            const latRef = EXIF.getTag(this, 'GPSLatitudeRef');
            const lon = EXIF.getTag(this, 'GPSLongitude');
            const lonRef = EXIF.getTag(this, 'GPSLongitudeRef');

            if (lat === undefined || lon === undefined) {
                alert('Image does not contain GPS data.');
                setWilayahTerdekatTampil(false);
                setOptions([]);
                return;
            }
            var latitude = convertDMSToDD(lat, latRef);
            var longitude = convertDMSToDD(lon, lonRef);

            setTengahLongi(longitude)
            setTengahLati(latitude);

            setLokasiGps({ lat: latitude, lng: longitude });

            setLongiTengah(longitude);
            setLatiTengah(latitude);
            // populateRadioButton(latitude, longitude);

        })

    }

    const handleFotoContoh = (e) => {

        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setFotoContohImg(reader.result);
        };

        reader.readAsDataURL(file);

        setFotoContoh(e.target.files[0]);

        // Check if the file is an image
        if (!file || !file.type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }

        EXIF.getData(file, function () {
            // Check if GPS data exists
            const lat = EXIF.getTag(this, 'GPSLatitude');
            const latRef = EXIF.getTag(this, 'GPSLatitudeRef');
            const lon = EXIF.getTag(this, 'GPSLongitude');
            const lonRef = EXIF.getTag(this, 'GPSLongitudeRef');

            if (lat === undefined || lon === undefined) {
                alert('Image does not contain GPS data.');
                setWilayahTerdekatTampil(false);
                setOptions([]);
                return;
            }
            var latitude = convertDMSToDD(lat, latRef);
            var longitude = convertDMSToDD(lon, lonRef);

            setContohLongi(longitude);
            setContohLati(latitude)
            // setLokasiGps({ lat: latitude, lng: longitude });
            // populateRadioButton(latitude, longitude);

            populateRadioButton(latiTengah, longiTengah);

        })

    }


    const populateRadioButton = async (latitude, longitude) => {
        setIsLoading(true)
        setWilayahTerdekatTampil(true)
        setDisplayGps(false);

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {

            const response = await fetch(`${apiUrl}/api/depan/getLokasiTerdekat/${latitude}/${longitude}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': user.csrfToken,
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                },

            });
            const data = await response.json();
            const updatedData = [...data];
            // updatedData[0].label = updatedData[0].label + ` => [terdekat]`;

            setOptions(updatedData);
            // setOptions(data);
            setIsLoading(false)
            setDisplayGps(true);
            setWilayahTerdekatTampil(true)


        } catch (error) {
            console.error('Error:', error);
        }
    }

    function convertDMSToDD(dms, ref) {
        var sign = ref == 'N' || ref == 'E' ? 1 : -1;
        var d = dms[0];
        var m = dms[1];
        var s = dms[2];
        return sign * (d + m / 60 + s / 3600);
    }


    const handlePetaSalah = async (e) => {

        console.log('konfirmasi lokasi benar atau salah')

        setGpsLoaded(false)
        setFotoGerbang(null);
        setFotoTengah(null);
        setFotoContoh(null)
        setOptions([]);
        setDisplayGps(false);
        setWilayahTerdekatTampil(false)

    }

    const handleSubmit = async (e) => {


        setIsButtonProcess(true);
        e.preventDefault();

        // alert('item di add');
        var isTambahData = true;

        if (user.perumahan_state == 'edit') {
            isTambahData = false;
        } else {
            isTambahData = true;
        }



        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        //  formData.append('id', props.sendItem.id);
        formData.append('id', user.perumahan_id);
        formData.append('is_tambah_data', isTambahData);
        formData.append('pengembang_id', user.pengembang_id);
        formData.append('kode_wilayah', selectedOption);
        formData.append('nama_perumahan', namaPerumahan);
        formData.append("jenisPerumahan", jenisPerumahan);
        formData.append("no_imb", nomorIMB);
        formData.append("pemilikIMB", nikNPWPPemilikIMB);
        formData.append("tgl_imb", tglTerbitIMB);

        formData.append("imb_pdf", fileUploadIMB);
        formData.append("siteplan_ttd", fileUploadSitePlan);
        formData.append("input_foto_gerbang", fotoGerbang);
        formData.append("input_foto_tengah", fotoTengah);
        formData.append("input_foto_contoh", fotoContoh);

        formData.append("kodeWilayahDiPilih", selectedOption);
        formData.append("longitude_gerbang", gerbangLongi);
        formData.append("latitude_gerbang", gerbangLati);
        formData.append("longitude_tengah", tengahLongi);
        formData.append("latitude_tengah", tengahLati);
        formData.append("longitude_contoh", contohLongi);
        formData.append("latitude_contoh", contohLati);

        formData.append("siteplan_latar", fotoSiteplan);
        formData.append("siteplan_jalur_air", fotoJalurAir);



        // console.log(formData);


        // for (var key of formData.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }


        const sUrl = apiUrl + '/api/depan/simpanLokasiPerumahan';
        // const sUrl ='http://localhost:8080/api/depan/simpanLokasiPerumahan';
        console.log(sUrl);
        try {
            await fetch(sUrl, {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);


                    // alert(data.status);

                    if (data) {

                        if (data.status === 'Gagal') {

                            Toast.fire({
                                icon: 'error',
                                title: data.status
                            });
                        } else {
                            Toast.fire({
                                icon: 'success',
                                title: data.status
                            });
                        }

                    }


                    setIsButtonProcess(false);
                    // navigate('/depan/pengembang/PengembangAdminListLokasi');
                    navigate('/depan/pengembang/PengembangAdminListLokasi2');

                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    setIsButtonProcess(false);
                    // alert(error);

                    Toast.fire({
                        icon: 'error',
                        title: error
                    });
                });

        } catch (error) {
            console.error('An error occurred:', error);
            setIsButtonProcess(false);
        }

    };



  return (
    <>
      
          <div className="modal fade" id="ModalTambahLokasi" tabIndex={-1} role="dialog" aria-labelledby="ModalTambahLokasiLabel" aria-hidden="true" data-backdrop="static">

              <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title">Isi Data</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                          </button>
                      </div>
                      <div className="modal-body">
                          <section className="content">
                              <div className="container-fluid">


                                  <form onSubmit={handleSubmit}>

                                      {/* Main content */}
                                      <section className="content">
                                          <div className="container-fluid">
                                              <div className="row">
                                                  <div className="col-sm-12 col-lg-4">
                                                      <div className="card">
                                                          <div className="card-body mb-5">
                                                              <form>
                                                                  <div className="form-group">
                                                                      <h6 className="m-0">Isi Form</h6>
                                                                      <p className="text-muted mb-0"><small>Isi form untuk daftar perumahan. </small></p>
                                                                  </div>
                                                                  <br />
                                                                  <div className="form-group">
                                                                      <label htmlFor="dafaultLabel" className="text-gray-dark">Nama Perumahan</label>
                                                                      {/* <input type="name" className="form-control" id="settings" placeholder="xx-xx-xxxxx-xx" /> */}
                                                                      <input
                                                                          placeholder='Cth. Taman Anggrek Kuningan'
                                                                          className="form-control" required data-pristine-pattern="/^((?!recycle).)*$/igm"
                                                                          // data-pristine-pattern-message="Nama perumahan tidak boleh menggunakan kata recycle" data-pristine-required-message="Nama Perumahan tidak boleh kosong"
                                                                          type="text"
                                                                          value={namaPerumahan}
                                                                          onChange={(e) => setNamaPerumahan(e.target.value)}
                                                                          require
                                                                      />
                                                                  </div>

                                                                  <div className="form-group">
                                                                      <label htmlFor="dafaultLabel" className="text-gray-dark">Jenis Bangunan</label>
                                                                      {/* <select className="form-control select2 select2-hidden-accessible" style={{ width: '100%' }} data-select2-id={10} tabIndex={-10} aria-hidden="true">
                                                        <option selected="selected" data-select2-id={10}>Pilih</option>
                                                        <option data-select2-id={100}>Rumah Subsidi Hijau</option>
                                                        <option data-select2-id={101}>Rumah Komersil Hijau</option>
                                                    </select> */}
                                                                      <select value={jenisPerumahan} onChange={handleJenisPerumahan} className="form-control" require >
                                                                          <option value="">Pilih</option>
                                                                          <option value="0">RUMAH TAPAK</option>
                                                                          <option value="1">RUMAH SUSUN</option>
                                                                      </select>
                                                                  </div>

                                                                  <div className="row">
                                                                      <div className="col-sm-6">
                                                                          <div className="form-group">
                                                                              <label htmlFor="dafaultLabel" className="text-gray-dark">Nomor IMG/PBG</label>
                                                                              {/* <input type="name" className="form-control" id="settings" placeholder="xx-xx-xxxxx-xx" /> */}
                                                                              <input
                                                                                  className="form-control" placeholder="2200/IMB/E/2019" required data-pristine-required-message="Nomor IMB/PBG tidak boleh kosong"
                                                                                  type="text"
                                                                                  value={nomorIMB}
                                                                                  onChange={(e) => setNomorIMB(e.target.value)}
                                                                                  require
                                                                              />
                                                                          </div>
                                                                      </div>
                                                                      <div className="col-sm-6">
                                                                          <div className="form-group">
                                                                              <label htmlFor="dafaultLabel" className="text-gray-dark">Tgl Terbit IMG/PBG</label>
                                                                              <div className="input-group">
                                                                                  <div className="input-group-prepend">
                                                                                      <span className="input-group-text"><i className="far fa-calendar-alt" /></span>
                                                                                  </div>
                                                                                  {/* <input type="text" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask inputMode="numeric" /> */}

                                                                                  <input
                                                                                      className="form-control bg-white" required data-pristine-required-message="Tanggal terbit IMB/PBG tidak boleh kosong"
                                                                                      type="date"
                                                                                      value={tglTerbitIMB}
                                                                                      onChange={(e) => setTglTerbitIMB(e.target.value)}
                                                                                      require
                                                                                  />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </form>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-12 col-lg-8">
                                                      <div className="card">
                                                          <div className="card-body mb-0">
                                                              <div className="form-group">
                                                                  <h6 className="m-0">Unggah File</h6>
                                                                  <p className="text-muted mb-0"><small>Unggah file sesuai dengan syarat dan ketentuan. </small></p>
                                                              </div>
                                                              <br />
                                                              <div className="row mb-0">
                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="dafaultLabel" className="text-gray-dark">IMB/PBG Induk Cap Dinas</label>
                                                                          <div className="custom-file">
                                                                              {/* <input type="file" className="custom-file-input" id="customFile" /> */}
                                                                              <input
                                                                                  className="form-control form-control"
                                                                                  data-pristine-required-message="Dokumen IMB/PBG tidak boleh kosong" required
                                                                                  type="file"
                                                                                  accept="application/pdf" // Spesifikasikan jenis file yang diizinkan
                                                                                  onChange={(e) => setFileUploadIMB(e.target.files[0])}
                                                                                  require

                                                                              />
                                                                              {/* <label className="custom-file-label" htmlFor="customFile">Choose file</label> */}
                                                                          </div>
                                                                          <small>1. File Dokumen harus dalam <b>format .pdf</b>
                                                                              <br />
                                                                              2. Dokumen disahkan lengkap dengan <b>cap dinas dan tanda
                                                                                  tangan</b></small>
                                                                      </div>
                                                                  </div>
                                                                  <br />
                                                                  <div className="col-md-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="dafaultLabel" className="text-gray-dark">Siteplan Digital</label>
                                                                          <div className="custom-file">
                                                                              {/* <input type="file" className="custom-file-input" id="customFile" /> */}
                                                                              <input
                                                                                  className="form-control form-control"
                                                                                  data-pristine-required-message="Jpg Siteplan  latar tidak boleh kosong" required
                                                                                  type="file"
                                                                                  accept=".jpg,.jpeg"
                                                                                  onChange={(e) => setFotoSiteplan(e.target.files[0])}
                                                                                  require
                                                                              />
                                                                              {/* <label className="custom-file-label" htmlFor="customFile">Choose file</label> */}
                                                                          </div>
                                                                          <small>1. File Foto harus dalam <b>format .jpg/.jpeg</b>
                                                                              <br />
                                                                              2. Siteplan digital <b>(latar putih dengan garis pinggir
                                                                                  hitam)</b> dan terdapat simbol mata angin</small>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              <br />
                                                              <div className="row">
                                                                  <div className="col-sm-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="dafaultLabel" className="text-gray-dark">Siteplan Perumahan Cap Dinas</label>
                                                                          <div className="custom-file">
                                                                              {/* <input type="file" className="custom-file-input" id="customFile" /> */}
                                                                              <input
                                                                                  className="form-control form-control"
                                                                                  data-pristine-required-message="Dokumen Siteplan  tidak boleh kosong" required
                                                                                  type="file"
                                                                                  // accept=".jpg,.jpeg,.pdf" 
                                                                                  accept=".pdf"
                                                                                  onChange={(e) => setFileUploadSitePlan(e.target.files[0])}
                                                                                  require
                                                                              />
                                                                              {/* <label className="custom-file-label" htmlFor="customFile">Choose file</label> */}
                                                                          </div>
                                                                          <small>1. File Dokumen harus dalam <b>format .pdf</b>
                                                                              <br />
                                                                              2. Dokumen Siteplan Pemda disahkan lengkap dengan <b>cap dinas, tanda tangan dan
                                                                                  NIP pejabat
                                                                                  berwenang</b></small>
                                                                      </div>
                                                                  </div>
                                                                  <br />
                                                                  <div className="col-sm-6">
                                                                      <div className="form-group">
                                                                          <label htmlFor="dafaultLabel" className="text-gray-dark">Siteplan Perumahan Jalur Air</label>
                                                                          <div className="custom-file">
                                                                              {/* <input type="file" className="custom-file-input" id="customFile" /> */}
                                                                              <input
                                                                                  className="form-control form-control"
                                                                                  data-pristine-required-message="Jpg Siteplan  latar tidak boleh kosong" required
                                                                                  type="file"
                                                                                  accept=".jpg,.jpeg"
                                                                                  onChange={(e) => setFotoJalurAir(e.target.files[0])}
                                                                                  require
                                                                              />
                                                                              {/* <label className="custom-file-label" htmlFor="customFile">Choose file</label> */}
                                                                          </div>
                                                                          <small>File Foto harus dalam <b>format .jpg/.jpeg</b></small>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </section>
                                      <br />

                                      {/* Main content */}
                                      <section className="content">
                                          <div className="container-fluid">
                                              <div className="row mb-2 mt-1">
                                                  <div className="col-sm-6">
                                                      <h4 className="text-muted fw-light">Unggah  <b>Foto Perumahan</b></h4>
                                                      <h6 className="text-muted fw-light">Foto yang unggah akan ditampilkan di website "unggah foto terbaik"
                                                      </h6>
                                                  </div>{/* /.col */}
                                                  {/* /.content-card-body */}
                                              </div>{/* /.row */}
                                              <div className="card">
                                                  <div className="card-body">
                                                      <div className="row mb-5">
                                                          <div className="col-md-4 col-lg-4 mb-3">
                                                              <div className="card h-60">

                                                                  <img id="uploaded-image-tengah" src={fotoGerbangImg} className="card-img-top" />

                                                                  <div className="card-body">
                                                                      <p className="text-bold text-muted h5">FOTO GERBANG DEPAN/ JALAN UTAMA</p>
                                                                      <p className="text-muted text-red mt-2">Unggah Foto yang terdapat lokasi koordinat (GPS)
                                                                      </p>
                                                                      {/* <input required data-pristine-required-message="Foto Gerbang" className="text-muted" type="file" accept="image/jpeg" /> */}

                                                                      <input
                                                                          required
                                                                          data-pristine-required-message="Foto Gerbang" className="svelte-zttspj"
                                                                          type="file"
                                                                          accept="image/jpeg" // Spesifikasikan jenis file yang diizinkan
                                                                          // onChange={(e) => setFotoGerbang(e.target.files[0])}
                                                                          onChange={handleFotoGerbang}
                                                                          require
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="col-md-4 col-lg-4 mb-3">
                                                              <div className="card h-60">
                                                                  <img className="card-img-top" src={fotoTengahImg} alt="Card image cap" />
                                                                  <div className="card-body">
                                                                      <p className="text-bold text-muted h5">FOTO TENGAH PERUMAHAN</p>
                                                                      <p className="text-muted text-red mt-2">Unggah Foto yang terdapat lokasi koordinat (GPS)
                                                                      </p>
                                                                      {/* <input required data-pristine-required-message="Foto Gerbang" className="text-muted" type="file" accept="image/jpeg" /> */}
                                                                      <input
                                                                          required
                                                                          data-pristine-required-message="Foto tengah" className="svelte-zttspj"
                                                                          type="file"
                                                                          accept="image/*" // Spesifikasikan jenis file yang diizinkan
                                                                          // onChange={(e) => setFotoGerbang(e.target.files[0])}
                                                                          onChange={(e) => handleFotoTengah(e)}
                                                                          require
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="col-md-4 col-lg-4 mb-3">
                                                              <div className="card h-60">
                                                                  <img className="card-img-top" src={fotoContohImg} alt="Card image cap" />
                                                                  <div className="card-body">
                                                                      <p className="text-bold text-muted h5">FOTO CONTOH RUMAH</p>
                                                                      <p className="text-muted text-red mt-2">Unggah Foto yang terdapat lokasi koordinat (GPS)
                                                                      </p>
                                                                      {/* <input required data-pristine-required-message="Foto Gerbang" className="text-muted" type="file" accept="image/jpeg" /> */}
                                                                      <input
                                                                          required
                                                                          data-pristine-required-message="Foto Contoh" className="svelte-zttspj"
                                                                          type="file"
                                                                          accept="image/*" // Spesifikasikan jenis file yang diizinkan
                                                                          // onChange={(e) => setFotoGerbang(e.target.files[0])}
                                                                          onChange={(e) => handleFotoContoh(e)}
                                                                          require
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </section>

                                      <section className="content">
                                          <div className="container-fluid">
                                              <div className="row">
                                                  <div className="col-sm-12 col-lg-5">
                                                      <div className="card">
                                                          <div className="card-body">
                                                              <div className="form-group">
                                                                  <h6 className="m-0">Hasil lokasi</h6>
                                                                  <p className="text-muted mb-0"><small>Pilih lokasi terdekat sesuai dengan IMB/PBG </small></p>
                                                              </div>
                                                              <br />

                                                              <div className="row mb-6">
                                                                  {wilayahTerdekatTampil ? (
                                                                      <div className='row'>
                                                                          {isLoading ? (
                                                                              <div>Loading...</div>
                                                                          ) : (
                                                                              <>
                                                                                  {options.map((option, index) => (
                                                                                      <div key={option.value}
                                                                                          className={`col-12 text-left  ${index === 0 ? 'text-bold' : 'text-muted'} `}
                                                                                      >

                                                                                          <label>
                                                                                              <input
                                                                                                  required
                                                                                                  type="radio"
                                                                                                  name="location"
                                                                                                  value={option.value}
                                                                                                  // checked={selectedOption === option.value}
                                                                                                  onChange={handleOptionChange}
                                                                                              />
                                                                                              {' ' + option.label + ' '}
                                                                                              {index === 0 ?
                                                                                                  <span className="badge bg-maroon">Terdekat</span>
                                                                                                  : ''}
                                                                                          </label>


                                                                                      </div>
                                                                                  ))}

                                                                              </>
                                                                          )}
                                                                      </div>
                                                                  ) : null}

                                                              </div>


                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-12 col-lg-7">
                                                      <div className="card">
                                                          <div className="card-body mb-3">
                                                              <div className="form-group">
                                                                  <h6 className="m-0">Map</h6>
                                                                  <p className="text-muted mb-0"><small>Map sesuai dengan lokasi gps foto yang diupload </small></p>
                                                              </div>
                                                              <br />
                                                              <div className="row mb-3">

                                                                  <div className="col-lg-12">
                                                                      <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">
                                                                          {wilayahTerdekatTampil && displayGps &&
                                                                              <>
                                                                                  <MapContainer
                                                                                      gps={lokasiGps}
                                                                                      onMapContainerReady={handleMapContainerReady}
                                                                                  />
                                                                              </>
                                                                          }
                                                                      </CustomIframe>
                                                                      {gpsLoaded && <div className="d-flex justify-content-center mt-4 mb-4">
                                                                          <button
                                                                              className="btn btn-success"
                                                                              disabled={isButtonProcess}
                                                                          >Simpan Lokasi Perumahan</button>
                                                                      </div>}
                                                                  </div>

                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div></section>
                                      {/* /.content */}

                                  </form>




                              </div>
                          </section>
                      </div>

                  </div>
              </div>
          </div>
    </>
  )
}

export default ModalLokasiTambah
