import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';

const TaBghHasilPenilaian = () => {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [optionsUnitRumah, setOptionsUnitRumah] = useState([]);
    const [selectedOptionUnitRumah, setSelectedOptionUnitRumah] = useState('');
    const { user, setUser } = useContext(AuthContext);

    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [optionsPengembang, setOptionsPengembang] = useState([]);
    const [selectedOptionPengembang, setSelectedOptionPengembang] = useState('');
    const [optionsPerumahan, setOptionsPerumahan] = useState([]);
    const [selectedOptionPerumahan, setSelectedOptionPerumahan] = useState('');


    const [optionsTypeRumah, setOptionsTypeRumah] = useState([]);
    const [selectedOptionsTypeRumah, setSelectedOptionsTypeRumah] = useState('');

    const [selectedJenisDeret, setSelectedJenisDeret] = useState('');

    const [tokenMobile, setTokenMobile] = useState('');

    const [isButtonProcess, setIsButtonProcess] = useState(false);



    const [data, setData] = useState([]);
    

    const [sumA, setSumA] = useState(0);
    const [sumB, setSumB] = useState(0);
    const [sumC, setSumC] = useState(0);
    const [sumD, setSumD] = useState(0);
    const [sumE, setSumE] = useState(0);
    const [sumF, setSumF] = useState(0);
    const [sumG, setSumG] = useState(0);

    const [sumPerencanaan, setSumPerencanaan] = useState(0);


    const [A1a_Point, setA1a_Point] = useState('');
    const [A1a_Pdf, setA1a_Pdf] = useState('');
    const [A1b_Point, setA1b_Point] = useState('');
    const [A1b_Pdf, setA1b_Pdf] = useState('');
    const [A2a_Point, setA2a_Point] = useState('');
    const [A2a_Pdf, setA2a_Pdf] = useState('');
    const [A2b_Point, setA2b_Point] = useState('');
    const [A2b_Pdf, setA2b_Pdf] = useState('');
    const [A2c_Point, setA2c_Point] = useState('');
    const [A2c_Pdf, setA2c_Pdf] = useState('');
    const [A3_Point, setA3_Point] = useState('');
    const [A3_Pdf, setA3_Pdf] = useState('');
    const [A4_Point, setA4_Point] = useState('');
    const [A4_Pdf, setA4_Pdf] = useState('');

    const [B1a_Point, setB1a_Point] = useState('');
    const [B1a_Pdf, setB1a_Pdf] = useState('');
    const [B1b_Point, setB1b_Point] = useState('');
    const [B1b_Pdf, setB1b_Pdf] = useState('');
    const [B1c_Point, setB1c_Point] = useState('');
    const [B1c_Pdf, setB1c_Pdf] = useState('');
    const [B2a_Point, setB2a_Point] = useState('');
    const [B2a_Pdf, setB2a_Pdf] = useState('');
    const [B2b_Point, setB2b_Point] = useState('');
    const [B2b_Pdf, setB2b_Pdf] = useState('');
    const [B2c_Point, setB2c_Point] = useState('');
    const [B2c_Pdf, setB2c_Pdf] = useState('');
    const [B3a_Point, setB3a_Point] = useState('');
    const [B3a_Pdf, setB3a_Pdf] = useState('');
    const [B3b_Point, setB3b_Point] = useState('');
    const [B3b_Pdf, setB3b_Pdf] = useState('');
    const [B4_Point, setB4_Point] = useState('');
    const [B4_Pdf, setB4_Pdf] = useState('');


    const [C1_Point, setC1_Point] = useState('');
    const [C1_Pdf, setC1_Pdf] = useState('');
    const [C2_Point, setC2_Point] = useState('');
    const [C2_Pdf, setC2_Pdf] = useState('');



    const [G_Point, setG_Point] = useState('');
    const [G_Pdf, setG_Pdf] = useState('');


    const handleJenisDeretPerumahan = event => {
        setSelectedJenisDeret(event.target.value);
    }

    let refreshToken = null;


    const navigate = useNavigate()

    const loginBEMobile = async () => {
        const username = 'pemkotsurabayar1@gmail.com';
        const password = '123';

        try {

            // const response = await fetch('https://onegreen-be-mobile.indonesiahijau.id/api/auth/signin', {
                const response = await fetch('https://api-mobile.igahp.id/api/auth/signin', {
                method: 'POST',
                credentials: 'include', //getcookies
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-XSRF-TOKEN': csrfToken
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                // Successful login
                const data = await response.json();
                // Perform necessary actions after successful login
                // data.csrfToken = csrfToken;
                setTokenMobile(data.token);

                console.log('Api respon login mobile be :', data);

                // if (data.status==='0') {
                //     alert('Status user anda masih non aktif, segera aktifkan..')
                //     return;
                // }
                return data;
            } else {
                // Login failed
                const error = await response.json();
                console.log('gagal login..')
                // console.log(csrfToken)
                console.error('Login error:', error);
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };


    const getPenilaian = async (token) => {

        try {

            
            // const response = await fetch('https://onegreen-be-mobile.indonesiahijau.id/api/igahp/cms/getPenilaian?nomorPermohonan=' + inputValue, {
                const response = await fetch('https://api-mobile.igahp.id/api/igahp/cms/getPenilaian?nomorPermohonan=' + inputValue, {
                method: 'GET',
                credentials: 'include', //getcookies
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                    // 'X-XSRF-TOKEN': csrfToken
                },
                // body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                // Successful login
                const data = await response.json();
                // Perform necessary actions after successful login
                // data.csrfToken = csrfToken;
                // setTokenMobile(data.token);

                // console.log('Api penilaian :', data);

                // if (data.status==='0') {
                //     alert('Status user anda masih non aktif, segera aktifkan..')
                //     return;
                // }
                return data;
            } else {
                // Login failed
                const error = await response.json();
                console.log('gagal login..')
                // console.log(csrfToken)
                console.error('Login error:', error);
            }
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    useEffect(() => {

        // const script2 = document.createElement('script');
        // script2.src = "/js/toast.js";
        // script2.async = true;
        // document.body.appendChild(script2);


        // (async () => { 
        //     const data = await loginBEMobile();
        //     const dataPenilaian = await getPenilaian(data.token);
        //     for (var i = 0; i < dataPenilaian.penilaian.length; i++) {
        //         if (dataPenilaian.penilaian[i].id === "1") {
        //             console.log("Kode:", dataPenilaian.penilaian[i].kode);
        //             console.log("PDF:", dataPenilaian.penilaian[i].pdf);
        //             console.log("Poin:", dataPenilaian.penilaian[i].poin);
        //             setA1a_Point(dataPenilaian.penilaian[i].poin);
        //             setA1a_Pdf(dataPenilaian.penilaian[i].pdf);
        //         }
        //     }
        // })();

        // Fetch options from API and update state
        fetchOptionsFromApiPengembang()
            .then(data => setOptionsPengembang(data))
            .catch(error => console.error('Error fetching options:', error));

        // return () => { script2.remove(); }
    }, []);


    useEffect(() => {
        fetchOptionsFromApiPerumahan()
            .then(data => setOptionsPerumahan(data))
            .catch(error => console.error('Error fetching options:', error));

    }, [selectedOptionPengembang]);


    useEffect(() => {


        // (async () => {
        //     const data = await loginBEMobile();
        //     const dataPenilaian = await getPenilaian(data.token);
        //     for (var i = 0; i < dataPenilaian.penilaian.length; i++) {
        //         if (dataPenilaian.penilaian[i].id === "1") {
        //             console.log("Kode:", dataPenilaian.penilaian[i].kode);
        //             console.log("PDF:", dataPenilaian.penilaian[i].pdf);
        //             console.log("Poin:", dataPenilaian.penilaian[i].poin);
        //             setA1a_Point(dataPenilaian.penilaian[i].poin);
        //             setA1a_Pdf(dataPenilaian.penilaian[i].pdf);
        //         }
        //     }
        // })();


        fetchOptionsFromTypeRumah()
            .then(data => setOptionsTypeRumah(data))
            .catch(error => console.error('Error fetching options:', error));

    }, [selectedOptionPerumahan]);


    const fetchOptionsFromTypeRumah = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        var idLokasi = (selectedOptionPerumahan === '') ? 1 : selectedOptionPerumahan;

        try {
            const response = await axios.get(apiUrl + '/api/public/getTipeRumah/' + idLokasi, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            console.log('respon api unit rumah');
            console.log(response.data)
            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };



    const fetchOptionsFromApiPengembang = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/getAllPengembang', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchOptionsFromApiPerumahan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

        try {
            const response = await axios.get(apiUrl + '/api/igahp/lokasibypengembangid/' + idPengembang, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };

    const handleOptionChangePengembang = event => {
        setSelectedOptionPengembang(event.target.value);
    };

    const handleOptionChangePerumahan = event => {
        setSelectedOptionPerumahan(event.target.value);
    };

    const handleOptionChangeTypeRumah = event => {
        setSelectedOptionsTypeRumah(event.target.value);
    };


    const [inputValue, setInputValue] = useState('');
    const [apiData, setApiData] = useState(null);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };


    const handleButtonClick = () => {

        setIsButtonProcess(true);

        // Make API call here using fetch or any library like axios
        // fetch(`https://api.example.com/endpoint?input=${inputValue}`)
        //     .then(response => response.json())
        //     .then(data => setApiData(data))
        //     .catch(error => console.error('Error fetching data:', error));

        // alert (inputValue);
        (async () => {
            const data = await loginBEMobile();
            const dataPenilaian = await getPenilaian(data.token);

            setSumA(sumPointsForIds(dataPenilaian.penilaian, 1, 7));
            setSumB(sumPointsForIds(dataPenilaian.penilaian, 8, 16));
            setSumC(sumPointsForIds(dataPenilaian.penilaian, 17, 18));
            setSumD(sumPointsForIds(dataPenilaian.penilaian, 19, 22));
            setSumE(sumPointsForIds(dataPenilaian.penilaian, 23, 29));
            setSumF(sumPointsForIds(dataPenilaian.penilaian, 30, 32));
            setSumG(sumPointsForIds(dataPenilaian.penilaian, 33, 33));
            setSumPerencanaan(sumA+sumB+sumC+sumD+sumE+sumF+sumG);

            var arrData = [];
            dataPenilaian.penilaian.forEach((item) => {
                arrData[item.id] = {
                    poin: item.poin,
                    pdf: item.pdf
                }

            })

            arrData[100] = {
                pdf: dataPenilaian.pdfLengkap
            }

            setData(arrData);


        })();
        setIsButtonProcess(false);   
    };

    const sumPointsForIds = (datax, startId, endId) => {
        const filteredData = datax.filter(item => {
            const itemId = parseInt(item.id, 10);
            return itemId >= startId && itemId <= endId;
        });

        const sum = filteredData.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.poin;
        }, 0);

        return sum;
    };


    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Penilaian</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Penilaian</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">


                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">


                                        {/* <div className="form-group">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Pilih Pengembang</label>
                                                    <select className="form-control" value={selectedOptionPengembang} onChange={handleOptionChangePengembang}>
                                                        <option value="">Select an option</option>
                                                        {optionsPengembang.map(option => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.nama}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Pilih Perumahan</label>
                                                    <select className="form-control" value={selectedOptionPerumahan} onChange={handleOptionChangePerumahan}>
                                                        <option value="">Select an option</option>
                                                        {optionsPerumahan.map(option2 => (
                                                            <option key={option2.id} value={option2.id}>
                                                                {option2.nama_perumahan}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Type Rumah</label>
                                                    <select className="form-control" value={selectedOptionsTypeRumah} onChange={handleOptionChangeTypeRumah}>
                                                        <option value="">Select an option</option>
                                                        {optionsTypeRumah.map(option3 => (
                                                            <option key={option3.id} value={option3.id}>
                                                                {option3.nama}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <div className="mb-3">
                                                <label>Pilih Jenis Deret</label>
                                                <select value={selectedJenisDeret} onChange={handleJenisDeretPerumahan} className="form-control" >
                                                
                                                    <option value="">Pilih Jenis Rumah...</option>
                                                    <option value="1">Deret Tengah</option>
                                                    <option value="2">Deret Pojok</option>

                                                </select>
                                            </div>
                                        </div> */}


                                        <div className="form-group">
                                            <label>Nomor Permohonan</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                placeholder="Nomor Permohonan BGH"
                                            />
                                            <br />

                                            <button onClick={handleButtonClick} className="btn btn-info" disabled={isButtonProcess}>Hasil</button>

                                            {apiData && (
                                                <div>
                                                    <h2>API Response:</h2>
                                                    <pre>{JSON.stringify(apiData, null, 2)}</pre>
                                                </div>
                                            )}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#perencanaan" data-toggle="tab">Perencanaan</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#pembangunan" data-toggle="tab">Pembangunan</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#pemanfaatan" data-toggle="tab">Pemanfaatan</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#pembongkaran" data-toggle="tab">Pembongkaran</a></li>
                                        </ul>
                                    </div>{/* /.card-header */}
                                    <div className="card-body">

                                        <div className="tab-content">
                                            <div className="active tab-pane" id="perencanaan">

                                                <div className="card">
                                                    {/* <div className="card-header">
                                                        <h3 className="card-title">Perencanaan parameter</h3>
                                                    </div> */}
                                                    {/* /.card-header */}

                                                    <div className="card-body">
                                                        <table id="example2" className="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th>Grup Parameter</th>
                                                                    <th>Parameter</th>
                                                                    <th>Parameter Detail</th>
                                                                    <th>Target Poin</th>
                                                                    <th>Klaim Point</th>
                                                                    <th>Document</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>A. PENGELOLAAN TAPAK</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>21</td>
                                                                    <td>{sumA}</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>1. Pengelolaan Tapak
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Penutup atap dan perkerasan memiliki nilai pantul matahari (albedo) paling rendah 0,3.</td>
                                                                    <td>3</td>
                                                                    <td>{data[1]?.poin}</td>
                                                                    <td>
                                                                        {data[1]?.pdf ? (
                                                                            <a target="_blank" href={data[1].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>b. Tersedianya sarana pengelolaan air hujan untuk mengurangi beban jaringan drainase kota:
                                                                        <br />
                                                                        <small>
                                                                            <i>1. Tersedia tangki penampungan air hujan minimal 200 liter. (5 point)</i> <br />
                                                                            <i>2. Sumur resapan sesuai dengan ketentuan teknis dengan ukuran minimal diameter 60 cm dan kedalaman 100 cm. (4 point)</i>
                                                                        </small>
                                                                    </td>
                                                                    <td>4 atau 5</td>
                                                                    <td>{data[2]?.poin}</td>
                                                                    <td>
                                                                        {data[2]?.pdf ? (
                                                                            <a target="_blank" href={data[2].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>2. Ruang Terbuka Hijau (RTH) Privat</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Luas area hijau dari total luas lahan rumah
                                                                        <br />
                                                                        <small>
                                                                            <i>1. Area Hijau 10-20% (3 point)</i> <br />
                                                                            <i>2. Area Hijau diatas 20%. (4 point)</i>
                                                                        </small>

                                                                    </td>
                                                                    <td>3 atau 4</td>
                                                                    <td>{data[3]?.poin}</td>
                                                                    <td>
                                                                        {data[3]?.pdf ? (
                                                                            <a target="_blank" href={data[3].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>b. Terdapat penanaman vegetasi penghijauan berupa pohon peneduh minimal 1 pohon.</td>
                                                                    <td>3</td>
                                                                    <td>{data[4]?.poin}</td>
                                                                    <td>
                                                                        {data[4]?.pdf ? (
                                                                            <a target="_blank" href={data[4].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>c. Terdapat penanaman tanaman konsumsi seperti sayur, buah, bumbu dapur, dan/atau tanaman obat paling sedikit dengan luas 1 m2 dan dua jenis tanaman.</td>
                                                                    <td>2</td>
                                                                    <td>{data[5]?.poin}</td>
                                                                    <td>
                                                                        {data[5]?.pdf ? (
                                                                            <a target="_blank" href={data[5].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>3. Penyediaan Lahan Carport</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Lahan carport kendaraan tidak  mengambil lahan publik.</td>
                                                                    <td>2</td>
                                                                    <td>{data[6]?.poin}</td>
                                                                    <td>
                                                                        {data[6]?.pdf ? (
                                                                            <a target="_blank" href={data[6].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>4. Sistem Pencahayaan Ruang Luar</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Fasilitas penerangan ruang luar menggunakan saklar otomatis atau sensor cahaya.</td>
                                                                    <td>2</td>
                                                                    <td>{data[7]?.poin}</td>
                                                                    <td>
                                                                        {data[7]?.pdf ? (
                                                                            <a target="_blank" href={data[7].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>B. EFISIENSI PENGGUNAAN ENERGI</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>46</td>
                                                                    <td>{sumB}</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>1. Selubung Bangunan</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Terdapat peneduh/shading pada semua kaca dengan tampak Utara dan Selatan.</td>
                                                                    <td>5</td>
                                                                    <td>{data[8]?.poin}</td>
                                                                    <td>
                                                                        {data[8]?.pdf ? (
                                                                            <a target="_blank" href={data[8].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>b. Terdapat peneduh/shading pada semua kaca dengan tampak Timur dan Barat.</td>
                                                                    <td>6</td>
                                                                    <td>{data[9]?.poin}</td>
                                                                    <td>
                                                                        {data[9]?.pdf ? (
                                                                            <a target="_blank" href={data[9].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>c. Perbandingan luas kaca atau bidang transparan dengan luas dinding sisi luar pada kamar tidur (Window to Wall Ratio/WWR):
                                                                        <br />
                                                                        <small>
                                                                            <i>1. Luas kaca 5 - 10% dari luas dinding pada satu sisi orientasi bangunan. (5 point)</i> <br />
                                                                            <i>2. Luas kaca 10 - 15% dari luas dinding pada satu sisi orientasi bangunan. (4 point)</i>
                                                                        </small>

                                                                    </td>
                                                                    <td>4 atau 5</td>
                                                                    <td>{data[10]?.poin}</td>
                                                                    <td>
                                                                        {data[10]?.pdf ? (
                                                                            <a target="_blank" href={data[10].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>2. Sistem Pengondisian Udara/ Air Conditioner (AC)</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Penggunaan AC paling banyak 25% dari total luas bangunan. Catatan: Apabila tidak menggunakan AC, maka mendapatkan nilai penuh.</td>
                                                                    <td>5</td>
                                                                    <td>{data[11]?.poin}</td>
                                                                    <td>
                                                                        {data[11]?.pdf ? (
                                                                            <a target="_blank" href={data[11].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>b. AC yang digunakan memiliki label hemat energi minimal bintang 4 sesuai dengan Kementerian ESDM. Catatan: Apabila tidak menggunakan AC, maka mendapatkan nilai penuh</td>
                                                                    <td>5</td>
                                                                    <td>{data[12]?.poin}</td>
                                                                    <td>
                                                                        {data[12]?.pdf ? (
                                                                            <a target="_blank" href={data[12].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>c. Ruangan yang menggunakan AC dilengkapi dengan lubang ventilasi untuk suplai udara segar paling sedikit 1% dari luas ruangan dan terletak pada dua sisi dinding yang berbeda.
                                                                        Catatan: Apabila tidak menggunakan AC, maka mendapatkan nilai penuh</td>
                                                                    <td>5</td>
                                                                    <td>{data[13]?.poin}</td>
                                                                    <td>
                                                                        {data[13]?.pdf ? (
                                                                            <a target="_blank" href={data[13].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>3. Sistem Pencahayaan</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Untuk pemanfaatan pencahayaan alami, kedalaman ruangan tidak lebih dari 2 kali tinggi ambang atas jendela.</td>
                                                                    <td>5</td>
                                                                    <td>{data[14]?.poin}</td>
                                                                    <td>
                                                                        {data[14]?.pdf ? (
                                                                            <a target="_blank" href={data[14].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>b. Sistem pencahayaan buatan memiliki daya maksimum sesuai dengan standar.</td>
                                                                    <td>5</td>
                                                                    <td>{data[15]?.poin}</td>
                                                                    <td>
                                                                        {data[15]?.pdf ? (
                                                                            <a target="_blank" href={data[15].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>4. Sistem Kelistrikan</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Terdapat daya listrik dari sumber energ terbarukan minimal 10% dari total kebutuhan daya listrik.</td>
                                                                    <td>5</td>
                                                                    <td>{data[16]?.poin}</td>
                                                                    <td>
                                                                        {data[16]?.pdf ? (
                                                                            <a target="_blank" href={data[16].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>C. EFISIENSI PENGGUNAAN AIR</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>30</td>
                                                                    <td>{sumC}</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>1. Sumber Air</td>
                                                                    <td><i>Pilih Salah Satu :</i></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Air PDAM atau perusahaan air minum lainnya (Point 11)<br />
                                                                        b. Air hujan yang diolah secara sederhana untuk dimanfaatkan sebagai alternatif sumber air (Point 15) <br />
                                                                        c. Dalam hal tidak ada sambungan rumah dari PDAM atau perusahaan air minum lainnya, dapat menggunakan sumber air tanah yang harus dilengkapi dengan meter air. (Point 8)
                                                                    </td>
                                                                    <td>11 , 15 atau 8</td>
                                                                    <td>{data[17]?.poin}</td>
                                                                    <td>
                                                                        {data[17]?.pdf ? (
                                                                            <a target="_blank" href={data[17].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>2. Penggunaan Peralatan Saniter Hemat Air (Water Fixture)</td>
                                                                    <td><i>Pilih Salah Satu :</i></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Penggunaan paling sedikit 25% produk saniter hemat air dari total produk saniter (Point 10)<br />
                                                                        b. Penggunaan paling sedikit 50% produk saniter hemat air dari total produk saniter (Point 12)<br />
                                                                        c. Penggunaan paling sedikit 75% produk saniter hemat air dari total produk saniter (Point 15)
                                                                    </td>
                                                                    <td>10, 12 atau 15</td>
                                                                    <td>{data[18]?.poin}</td>
                                                                    <td>
                                                                        {data[18]?.pdf ? (
                                                                            <a target="_blank" href={data[18].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>D. KUALITAS UDARA DALAM RUANG</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>18</td>
                                                                    <td>{sumD}</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>1. Sirkulasi Udara dalam Ruang</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Penggunaan jendela yang dapat dibuka (operable window) dan/atau lubang ventilasi:
                                                                        <br />
                                                                        <small>
                                                                            <i>1. Luas bukaan paling sedikit 5% dari luas lantai pada setiap ruangan (3 point)</i> <br />
                                                                            <i>2. Luas bukaan paling sedikit 10% dari luas lantai pada setiap ruangan (5 point)</i>
                                                                        </small>
                                                                    </td>
                                                                    <td>3 atau 5</td>
                                                                    <td>{data[19]?.poin}</td>
                                                                    <td>
                                                                        {data[19]?.pdf ? (
                                                                            <a target="_blank" href={data[19].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>b. Terdapat ventilasi silang setidaknya pada ruang tamu dan ruang keluarga dengan kedua bukaan tidak berada pada satu garis lurus</td>
                                                                    <td>3</td>
                                                                    <td>{data[20]?.poin}</td>
                                                                    <td>
                                                                        {data[20]?.pdf ? (
                                                                            <a target="_blank" href={data[20].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>c. Kamar mandi dan dapur dilengkapi dengan exhaust fan apabila tidak terdapat lubang ventilasi.
                                                                        Catatan: Apabila kamar mandi dan dapur dilengkapi dengan lubang ventilasi, maka mendapatkan poin penuh.</td>
                                                                    <td>5</td>
                                                                    <td>{data[21]?.poin}</td>
                                                                    <td>
                                                                        {data[21]?.pdf ? (
                                                                            <a target="_blank" href={data[21].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>2. Pengendalian Penggunaan Bahan Pembeku (Refrigerant)</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Rumah menggunakan AC yang memiliki label "bebas CFC dan HCFC" sesuai ketentuan Kementerian Perindustrian. Catatan: Apabila tidak menggunakan AC, maka mendapatkan nilai penuh.</td>
                                                                    <td>5</td>
                                                                    <td>{data[22]?.poin}</td>
                                                                    <td>
                                                                        {data[22]?.pdf ? (
                                                                            <a target="_blank" href={data[22].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>E. PENGGUNAAN MATERIAL RAMAH LINGKUNGAN</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>15</td>
                                                                    <td>{sumE}</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>1. Material beton menggunakan bahan baku yang berasal dari jarak paling jauh 1.000 km atau berasal dari penyedia terdekat dari lokasi proyek.</td>
                                                                    <td></td>
                                                                    <td>1</td>
                                                                    <td>{data[23]?.poin}</td>
                                                                    <td>
                                                                        {data[23]?.pdf ? (
                                                                            <a target="_blank" href={data[23].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>2. Material beton penggunaan semen terdapat ketentuan rencana menggunakan semen dari pabrik yang menerapkan sistem manajemen lingkungan ISO 14001, penerapan Sertifikasi Industri Hijau (SIH), dan/atau skema label ramah lingkungan</td>
                                                                    <td></td>
                                                                    <td>3</td>
                                                                    <td>{data[24]?.poin}</td>
                                                                    <td>
                                                                        {data[24]?.pdf ? (
                                                                            <a target="_blank" href={data[24].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>3. Material dinding terdapat ketentuan harus berasal dari jarak paling jauh 1.000 km atau berasal dari penyedia terdekat dari lokasi proyek.</td>
                                                                    <td></td>
                                                                    <td>1</td>
                                                                    <td>{data[25]?.poin}</td>
                                                                    <td>
                                                                        {data[25]?.pdf ? (
                                                                            <a target="_blank" href={data[25].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>4. Seluruh penggunaan kayu (kayu olahan dan/atau kayu konstruksi) memiliki ketentuan legal dan/atau skema label ramah lingkungan.</td>
                                                                    <td></td>
                                                                    <td>4</td>
                                                                    <td>{data[26]?.poin}</td>
                                                                    <td>
                                                                        {data[26]?.pdf ? (
                                                                            <a target="_blank" href={data[26].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>5. Penggunaan material cat dengan ketentuan memilih dari pabrik yang menerapkan sistem manajemen lingkungan ISO 14001 dan/atau skema label ramah lingkungan.</td>
                                                                    <td></td>
                                                                    <td>1</td>
                                                                    <td>{data[27]?.poin}</td>
                                                                    <td>
                                                                        {data[27]?.pdf ? (
                                                                            <a target="_blank" href={data[27].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>6. Penggunaan material penutup atap yang ramah lingkungan, yaitu tidak mengandung bahan beracun dan berbahaya (B3) antara lain asbes, dan/atau skema label ramah lingkungan.</td>
                                                                    <td></td>
                                                                    <td>2</td>
                                                                    <td>{data[28]?.poin}</td>
                                                                    <td>
                                                                        {data[28]?.pdf ? (
                                                                            <a target="_blank" href={data[28].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>7. Penggunaan Tingkat Komponen Dalam Negeri (TKDN) gabungan dalam pengerjaan rumah paling sedikit 40%.</td>
                                                                    <td></td>
                                                                    <td>3</td>
                                                                    <td>{data[29]?.poin}</td>
                                                                    <td>
                                                                        {data[29]?.pdf ? (
                                                                            <a target="_blank" href={data[29].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>F. PENGELOLAAN SAMPAH</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>23</td>
                                                                    <td>{sumF}</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>1. Penerapan Prinsip 3R (Reduce, Reuse, Recycle)</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Memiliki wadah sampah terpilah dan melakukan sistem pemilahan sampah minimal dua jenis (organik dan anorganik).</td>
                                                                    <td>8</td>
                                                                    <td>{data[30]?.poin}</td>
                                                                    <td>
                                                                        {data[30]?.pdf ? (
                                                                            <a target="_blank" href={data[30].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>2. Penerapan Sistem Penanganan Sampah</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Rumah mengolah sampah organik dengan komposter skala individual</td>
                                                                    <td>11</td>
                                                                    <td>{data[31]?.poin}</td>
                                                                    <td>
                                                                        {data[31]?.pdf ? (
                                                                            <a target="_blank" href={data[31].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>b. Adanya komitmen untuk melakukan upaya pengelolaan sampah anorganik yang disalurkan melalui pihak lain (misal bank sampah, pengepul, dll).</td>
                                                                    <td>4</td>
                                                                    <td>{data[32]?.poin}</td>
                                                                    <td>
                                                                        {data[32]?.pdf ? (
                                                                            <a target="_blank" href={data[32].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>G. PENGELOLAAN AIR LIMBAH</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>12</td>
                                                                    <td>{sumG}</td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>1. Apabila terletak di daerah pelayanan sistem jaringan air limbah kota/komunal, harus memanfaatkan jaringan tersebut:</td>
                                                                    <td><i>Pilih Salah Satu :</i></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>a. Jaringan pipa langsung terhubung tanpa prapengolahan (6 Point)<br />
                                                                        b. Dilengkapi dengan prapengolahan (bak kontrol, grease trap, bar screen, dan/atau sebagainya) (9 Point)</td>
                                                                    <td>6 atau 9</td>
                                                                    <td>{[6, 9].includes(data[33]?.poin) ? data[33]?.poin : ''}</td>
                                                                    <td>
                                                                        {[6, 9].includes(data[33]?.poin) ? (
                                                                            <a target="_blank" href={data[33]?.pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>2. Apabila tidak terletak di daerah pelayanan sistem jaringan air limbah kota/komunal, memiliki fasilitas pengolahan air limbah:</td>
                                                                    <td><i>Pilih Salah Satu :</i></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>

                                                                        a. Tangki septik sesuai standar  (7 Point) <br />
                                                                        b. Tangki septik dengan media (biofilter) sesuai dengan standar (8 Point)  <br />
                                                                        c. Tangki septik atau tangki septik dengan media (biofilter) sesuai standar yang dilengkapi
                                                                        dengan prapengolahan (grease trap dan/atau saringan) untuk air limbah tercampur (12 Point)

                                                                    </td>
                                                                    <td>7, 8 atau 12</td>
                                                                    <td>{[7, 8, 12].includes(data[33]?.poin) ? data[33]?.poin : ''}</td>
                                                                    <td>
                                                                        {[7, 8, 12].includes(data[33]?.poin) ? (
                                                                            <a target="_blank" href={data[33]?.pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}
                                                                    </td>

                                                                </tr>
                                                                <tr>

                                                                    <td>TOTAL POINT PERENCANAAN</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>165</td>
                                                                    <td>{sumA+sumB+sumC+sumD+sumE+sumF+sumG}</td>
                                                                    <td>
                                                                        {data[100]?.pdf ? (
                                                                            <a target="_blank" href={data[100].pdf}>
                                                                                PDF
                                                                            </a>
                                                                        ) : (
                                                                            'No PDF'
                                                                        )}
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                            {/* <tfoot>
                                                <tr>
                                                    <th>Rendering engine</th>
                                                    <th>Browser</th>
                                                    <th>Platform(s)</th>
                                                    <th>Engine version</th>
                                                    <th>CSS grade</th>
                                                </tr>
                                            </tfoot> */}
                                                        </table>
                                                    </div>
                                                    {/* /.card-body */}
                                                </div>
                                            </div>
                                            {/* /.tab-pane */}
                                            <div className="tab-pane" id="pembangunan">
                                            </div>
                                            {/* /.tab-pane */}
                                            <div className="tab-pane" id="pemanfaatan">
                                            </div>
                                            {/* /.tab-pane */}
                                            <div className="tab-pane" id="pembongkaran">

                                            </div>
                                            {/* /.tab-pane */}
                                        </div>
                                        {/* /.tab-content */}
                                    </div>{/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}

                        <div className="row">
                            <div className="col-12">

                                {/* /.card */}

                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

        </>
    )
}

export default TaBghHasilPenilaian
