import React from 'react'
import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import { useState } from 'react';


const Panduan = () => {

  // const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');



  // const [index, setIndex] = useState(0);
  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };


  const handleButtonClick = async () => {
    try {
      // Assuming you have an API endpoint and the appropriate method (POST, PUT, etc.)
      // const response = await fetch('http://localhost:8080/api/public/kirimEmailPertanyaan', {
      const response = await fetch('https://onegreen-be-cms.indonesiahijau.id/api/public/kirimEmailPertanyaan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: email,
          isi: question,
          subject: 'contact us'
        }),
      });

      // Handle the response accordingly (e.g., show success message, error handling)
      if (response.ok) {
        console.log('Request successful');
        alert('Pesan telah terkirim, harap tunggu balasan dari admin');
        // You can add further actions here upon successful API request
      } else {
        console.error('Error in API request');
        alert('Error in API request');
        // Handle error cases here
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error');
      // Handle other errors such as network issues
    } finally {

    }
  };


  require("./../../css/common.css");

  return (

    <>

      <div>

        <NavBarAtas showButton={true} />

        <section className="body-home-content-panduan">
          
        
        <div className="section-headlines-header-text">
          
            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">Panduan</div>
              <div className="igahp-l-regular black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
            </div>
            <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
              
            </div>

            
          </div>

          <section className="section-body-maxpage">
            <div className="body-card-panduan">

              <div className="group-card-panduan">
                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Guidelines
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number1.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Basic Design
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number2.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Toolkit
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number3.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                <div className="card-panduan">
                <div className="label-panduan">
                    <div className="igahp-subheading-semi-bold">
                    Administrasi PGB
                    </div>
                    <button className="btn-icon-only">
                      <img src="/ic_number4.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

              </div>

              {/* <div className="body-card-panduan">

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Guidelines
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Basic Design
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Toolkit
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

              <div className="group-card">
                <div className="card-product">
                <div className="label-product">
                    <div className="igahp-l-semi-bold">
                    Administrasi PGB
                    </div>
                    <button className="btn-icon-only">
                      <img src="/icon_arrow_right_green.svg" alt="" />
                    </button>
                  </div>
                  <div className="support-text">
                    <div className="igahp-m-regular">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                  </div>
                </div>

                

              </div>

            </div> */}

            </div>


          </section>



        
            


         

          <section className="section-body-maxpage">
            <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">LAPORKAN!!</div>
                    <div className="support-text-attention">
                      <p>Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan</p>
                      <p>Kementerian Perumahan dan Kawasan Permukiman</p></div>

                  </div>
                </div>
              </div>

            </div>

          </section>

        </section>

        <section className="wrapper-contact-us">
          <div className="wrapper-contact-us-content">
            <div className="wrapper-contact-us-left">
              <div className="igahp-contribute">
                <img src="/logo_igahp_white.png" alt="logo igahp" />
              </div>
            </div>
            <div className="wrapper-contact-us-right">

              <p className="igahp-h3-medium white-text" id="contact-us-title">Hubungi kami untuk mengetahui IGAHP</p>
              <div className="wrapper-contact-us-form-email">
                <img src="/ic_message_contact_us.svg" alt="" id="ic-message-contact-us" />
                <input
                  type="text"
                  placeholder="Masukkan email Anda"
                  className="form-rad-12 igahp-m-medium"
                  id="form-contact-us-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>


              <textarea
                placeholder="Masukkan pertanyaan"
                className="form-rad-12 igahp-m-medium"
                id="form-contact-us-question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <button className="btn-green-solid-pressed" id="btn-send-contact-us" type="button" onClick={handleButtonClick} disabled>
                <img src="/ic_send.svg" alt="Send" />
                <div className="igahp-m-semi-bold white-text">Kirim Pertanyaan</div>
              </button>

            </div>
          </div>
          {/* <img src="bg_top_right_contact_us.svg" id="bg-top-right-contact-us" /> */}
          {/* <img src="/ic_green_leaf_testimoni.svg" id="ic-bottom-left-contact-us" width="200" /> */}
        </section>

        <FooterFront />
      </div>

    </>



  )
}

export default Panduan
