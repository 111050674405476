import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { AuthContext } from "../../AuthContext";
import { useContext } from 'react';
import { refreshTokenRequest } from '../refreshTokenRequest';

import axios from 'axios';

const ListPengajuanBgh = () => {


    // const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();


    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    let refreshToken = null;

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);

    const { loginContext } = useContext(AuthContext);

    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl + '/api/igahp/pengembang/listPengajuanBgh', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    }

    let sequenceNumber = 1;


    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>List Pengajuan BGH</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">List Pengajuan BGH</li>
                                </ol>
                            </div>
                        </div>

                        {isLoading ?
                            <p>Loading Data...</p> :

                            <div className="card">
                                <div className="card-header">
                                    <div className="card-info">
                                        {/* <button type="submit" onClick={handleAddLokasi} className="btn btn-primary">Tambah Lokasi</button> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-12">

                                        </div>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <table id="example1" className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>NO PERMOHONAN</th>
                                                <th>NAMA PENGEMBANG</th>
                                                <th>NAMA PERUMAHAN</th>
                                                <th>STATUS PERMOHONAN</th>
                                                <th>TGL PERMOHONAN</th>
                                                <th>AKSI</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data.map((item) => (
                                                <tr key={item.id}>
                                                    {/* <td>{item.id}</td> */}
                                                    <td>{sequenceNumber++}</td>
                                                    <td>{item.nomorPermohonan}</td>
                                                    <td>{item.namaPengembang}</td>
                                                    <td>{item.namaPerumahan}</td>
                                                    <td>{item.statusPengajuan}</td>
                                                    <td>{item.tglPermohonan}</td>


                                                    {/* <td>{item.jenis_perumahan === 0 ? 'Rumah Tapak' : 'Rumah Susun'}</td>
                                                    <td>{item.verified === true ? 'Verified' : 'Belum Verified'}</td> */}
                                                    <td>
                                                        <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                            onClick={(event) => {
                                                                user['pengajuanbgh_id'] = item.id;
                                                                user['perumahanId'] = item.perumahanId;                                                                
                                                                loginContext(user); // isi context
                                                                // alert("allo");
                                                                navigate(`/depan/pemda/PengajuanBghDetail`);
                                                            }}
                                                        >
                                                            <i className="fas fa-eye" />
                                                        </button>

                                                        {/* <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                            
                                                            onClick={(event) => {
                                                                user['perumahan_id'] = item.id;
                                                                user['perumahan_nama'] = item.nama_perumahan;
                                                                user['perumahan_state'] = 'edit';
                                                                loginContext(user); // isi context

                                                                navigate(`/igahp/pengembang/PengembangLokasiTambah`);
                                                            }}
                                                        >
                                                            <i className="fas fa-edit" id={`i-${item.id}`} />

                                                        </button>


                                                        <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                            <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                        </button> */}
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }


                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">



                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

        </>
    )
}

export default ListPengajuanBgh
