import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LocationSelect = ({ onVillageChange, onProvinceChange, onCityChange, onDistrictChange, villageCode }) => {
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [villages, setVillages] = useState([]);

    const [provSelectedOption, setProvSelectedOption] = useState('');
    const [kotaSelectedOption, setKotaSelectedOption] = useState('');
    const [kecSelectedOption, setKecSelectedOption] = useState('');
    const [kelSelectedOption, setKelSelectedOption] = useState('');

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        fetchProvinces();
    }, []);

    useEffect(() => {
        initialSelected();
    }, [villageCode]);

    const initialSelected = async () => {
        try {
            if (villageCode) {
                setProvSelectedOption(villageCode.substring(0, 2));
                fetchCities(villageCode.substring(0, 2));
                setKotaSelectedOption(villageCode.substring(0, 4));
                fetchDistricts(villageCode.substring(0, 4));
                setKecSelectedOption(villageCode.substring(0, 6));
                fetchVillages(villageCode.substring(0, 6));
                setKelSelectedOption(villageCode);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchProvinces = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getProvinsi`);
            setProvinces(response.data);
        } catch (error) {
            console.error('Error fetching provinces:', error);
        }
    };

    const fetchCities = async (provinceId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKabKota/${provinceId}`);
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    const fetchDistricts = async (cityId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKecamatan/${cityId}`);
            setDistricts(response.data);
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    const fetchVillages = async (districtId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKelurahan/${districtId}`);
            setVillages(response.data);
        } catch (error) {
            console.error('Error fetching villages:', error);
        }
    };

    const handleProvinceChange = (e) => {
        const selectedProvinceId = e.target.value;
        setProvSelectedOption(selectedProvinceId);
        fetchCities(selectedProvinceId);
        setDistricts([]);
        setVillages([]);
        if (onProvinceChange) {
            onProvinceChange(selectedProvinceId);
        }
    };

    const handleCityChange = (e) => {
        const selectedCityId = e.target.value;
        setKotaSelectedOption(selectedCityId);
        fetchDistricts(selectedCityId);
        setVillages([]);
        if (onCityChange) {
            onCityChange(selectedCityId);
        }
    };

    const handleDistrictChange = (e) => {
        const selectedDistrictId = e.target.value;
        setKecSelectedOption(selectedDistrictId);
        fetchVillages(selectedDistrictId);
        if (onDistrictChange) {
            onDistrictChange(selectedDistrictId);
        }
    };

    const handleVillageChange = (e) => {
        const selectedVillageId = e.target.value;
        setKelSelectedOption(selectedVillageId);
        if (onVillageChange) {
            onVillageChange(selectedVillageId);
        }
    };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '20px' }}>
            <select onChange={handleProvinceChange} className="form-control" value={provSelectedOption}>
                <option>Pilih Provinsi</option>
                {provinces.map((province) => (
                    <option key={province.kode} value={province.kode}>{province.nama_wilayah}</option>
                ))}
            </select>

            <select onChange={handleCityChange} disabled={cities.length === 0} className="form-control" value={kotaSelectedOption}>
                <option value="">Pilih Kota/Kabupaten</option>
                {cities.map((city) => (
                    <option key={city.kode} value={city.kode}>{city.nama_wilayah}</option>
                ))}
            </select>

            <select onChange={handleDistrictChange} disabled={districts.length === 0} className="form-control" value={kecSelectedOption}>
                <option value="">Pilih Kecamatan</option>
                {districts.map((district) => (
                    <option key={district.kode} value={district.kode}>{district.nama_wilayah.toUpperCase()}</option>
                ))}
            </select>

            <select onChange={handleVillageChange} disabled={villages.length === 0} className="form-control" value={kelSelectedOption}>
                <option value="">Pilih Kelurahan</option>
                {villages.map((village) => (
                    <option key={village.kode} value={village.kode}>{village.nama_wilayah.toUpperCase()}</option>
                ))}
            </select>
        </div>
    );
};

export default LocationSelect;

/*

cara pakai :

import React, { useState } from 'react';
import LocationSelect from './LocationSelect';

const ParentComponent = () => {
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedVillage, setSelectedVillage] = useState('');

    return (
        <div>
            <h1>Location Selector</h1>
            <LocationSelect 
                onProvinceChange={setSelectedProvince} 
                onCityChange={setSelectedCity} 
                onDistrictChange={setSelectedDistrict} 
                onVillageChange={setSelectedVillage} 
            />

            <div>
                <h2>Selected Values</h2>
                <p>Province: {selectedProvince}</p>
                <p>City: {selectedCity}</p>
                <p>District: {selectedDistrict}</p>
                <p>Village: {selectedVillage}</p>
            </div>
        </div>
    );
};

export default ParentComponent;

*/